import React, { useState } from 'react';
import "../styles/accordian.scss";
import { FaArrowUp } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
const Accordion = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordian-container ${isOpen ? 'open' : ''}`}>
            <div className="accor-title" onClick={toggleAccordion}>
                <span className={`accor-arrow golden-span ${isOpen ? 'open' : ''}`}><FaArrowUp /></span>
                {props.title}
            </div>
            <div className={`accor-desc ${isOpen ? 'open' : ''}`}>
                {props.content && props.content}
                {props.list && props.list.map((item) => {
                    return (
                        <ul>
                            <li><span><IoMdCheckmarkCircleOutline /></span>{item}</li>
                        </ul>
                    )
                })}
            </div>
        </div>
    );
};

export default Accordion;
