import React, { useState } from 'react'
import Faqs from '../components/Faqs.js'
import "../styles/healthcare.scss"
import WhyChoose from '../components/WhyChoose'
import coaching from "../photos/healthcare/health-coach.webp"
import exam from "../photos/healthcare/physical.webp"
import fitness from "../photos/healthcare/nutrient.webp"
import Headertop from '../components/Headertop.js'
import { Helmet } from 'react-helmet'

function Healthcare() {
  const [faqstate,setfaqstate] = useState(3)
  const faq = [
      {
        title: "What is a medical strategy?",
        content: "A medical strategy is a comprehensive plan or approach developed to achieve specific healthcare goals, improve patient care, or address medical challenges."
      },
      {
        title: "Why is a medical strategy important in healthcare?",
        content: "A well-defined medical strategy helps healthcare organisations and providers optimize resources, enhance patient outcomes, and adapt to changing healthcare environments"
      },
      {
        title: "How can healthcare organisations develop effective healthcare strategies?",
        content: "Healthcare organisations can develop effective strategies by assessing their current operations, setting clear objectives, and collaborating with healthcare professionals and experts."
      },
      {
        title: "What role do physicians and surgeons play in healthcare strategy?",
        content: "Physicians and surgeons are key stakeholders in healthcare strategy development, as they provide clinical expertise and insights to shape medical policies and procedures."
      },
      {
        title: "Can healthcare strategy consulting firms assist in developing medical strategies?",
        content: "Yes, healthcare strategy consulting firms specialise in assisting healthcare organisations with strategy development, operational improvement, and navigating healthcare challenges."
      },
      {
        title: "How can consulting healthcare professionals benefit a healthcare organisation?",
        content: "Consulting healthcare professionals can offer valuable insights, recommendations, and expertise to improve healthcare delivery, quality, and efficiency."
      },
      {
        title: "When should I consult a physician for my health concerns?",
        content: "It's advisable to consult a physician when you have health concerns, experience symptoms, or need medical advice for preventive care or ongoing health management."
      },
      {
        title: "What is the difference between a physician and a surgeon?",
        content: "A physician is a medical doctor who provides diagnosis, treatment, and ongoing care for various medical conditions. A surgeon is a specialised physician who performs surgical procedures."
      },
      {
        title: "How can I find the right healthcare provider for my needs?",
        content: "To find the right healthcare provider, consider factors such as their specialty, location, reputation, and compatibility with your healthcare preferences."
      },
      {
        title: "What are some common health and care strategies for maintaining wellbeing?",
        content: "Common health and care strategies include regular check-ups, preventive measures like vaccinations, maintaining a balanced diet, exercising regularly, and managing stress."
      },
      {
        title: "Are there specific healthcare strategies for chronic conditions?",
        content: "Yes, healthcare strategies for chronic conditions often involve medication management, lifestyle changes, regular monitoring, and specialist consultations."
      },
      {
        title: "How can I take a proactive role in my healthcare?",
        content: "Taking a proactive role in your healthcare involves staying informed, following medical advice, making healthy choices, and actively participating in treatment decisions."
      },
      {
        title: "What is the role of technology in modern healthcare strategies?",
        content: "Technology plays a vital role in modern healthcare strategies by enhancing patient care, streamlining operations, and improving data analysis for better decision-making."
      },
      
      {
        title: "How can healthcare organisations prioritise patient-centered care in their strategies?",
        content: "Healthcare organisations can prioritise patient-centered care by involving patients in decision-making, improving communication, and tailoring services to individual needs."
      },

      
      
      {
        title: "How can I stay informed about changes in healthcare strategies and policies?",
        content: "You can stay informed about healthcare strategies and policies by following reputable healthcare news sources, attending conferences, and engaging with healthcare professionals and organisations."
      },
    ]


    const data1 =[
        {
        title:"Network of physicians",
        content:"We provide you with worldwide coverage through our resourceful, always up-to-date, and growing network of personal doctors and professionals seeking to provide you with the highest medical care.",
        },
    ]
  return (
    <>
     <Helmet>
      <title>Healthcare Strategy - Premium Healthcare in the UK</title>
      <meta name="description" content="Explore healthcare strategy solutions in the UK, including cutting-edge technology and comprehensive insurance options. Elevate your healthcare experience with our tailored approach."  />
      <meta name="keywords" content="healthcare services, healthcare uk, medical concierge, healthcare it services, healthcare app, health service, medical medical insurance, health care system in the uk, cares health" />
      <link rel="canonical" href="https://healthcareconcierge.net/healthcare-strategy" />
    </Helmet>
    <Headertop title="Healthcare Strategies" desc="Information, Advice, And Support"/>
        <div className="healthcare1-parent parent">
        <div className="healthcare1-whychooseus-container" >
                <WhyChoose
                img={coaching}
                
                indicator="why choose us"
                title="Stress Management and Life/ Health Coaching"
                content="Stress impacts individuals uniquely, influenced by personality, background, and environment. Our comprehensive services and coaching assess your stress levels and their impact on your body and health. We prioritise actionable steps to foster positive life changes, aiming to mitigate or eliminate stressors."
                direction=""
                 />
        </div>
    </div>
    <div className="healthcare2-parent parent">
        <div className="healthcare2-whychooseus-container" >
                <WhyChoose
                img={fitness}
                indicator="why choose us"
                title="Nutrition and Fitness-Focused Wellness"
                content="We provide tailored fitness and nutrition programs, utilising metabolic and genetic data to craft a wellness regimen that aligns with your unique physiology. Our goal is to enhance your health and longevity through a comprehensive approach that transforms the core of your wellbeing. Each Elite visit includes a complimentary consultation with our nutrition and fitness specialists. Wellness is an ongoing journey of nurturing a healthy body and mind. Our Elite physicians prioritise wellness as the foundation of your health during every examination."
                direction="row-reverse"
                 />
        </div>
    </div>
    <div className="healthcare3-parent parent">
        <div className="healthcare3-whychooseus-container" >
                <WhyChoose
                img={exam}
                
                indicator="why choose us"
                title="One-Stop Annual Health Check"
                content="Experience the Elite difference with our comprehensive Annual Executive Exam. Leave no stone unturned in your health assessment with tailored tests and screenings.
                We cater to your individual needs, regardless of medical history. Benefit from worldwide coverage through our extensive network of personal doctors and professionals, ensuring the highest standard of medical care."
                accor={data1}
                direction=""
                 />
        </div>
    </div>
    <div className="faqs_parent3 parent">
        <div className="faqs_container3_home container">
          <h2>FAQ for Healthcare Strategies</h2>
          {
            faq.slice(0,faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
          {
            faq.length >= faqstate &&  <button className='btn-dark-blue loadmore-btn' onClick={()=>setfaqstate(faqstate + 3)}>Load More</button>
          } 
          </center>
      
         
        </div>
      </div>
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default Healthcare