import React, { useEffect, useState } from 'react'
import "../styles/header.scss"
import { Link } from 'react-router-dom'
import { IoMdArrowDropdown } from "react-icons/io";
import { Divide as Hamburger } from 'hamburger-react'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
    duration: 1200
});
const Header = (props) => {
    const [toggle, settoggle] = useState(false)
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrolled(position > 80);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    //   turn off scroo
    useEffect(() => {
        if (toggle) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [toggle]);
    const [mob1, setmob1] = useState(true)
    const [mob2, setmob2] = useState(true)
    return (
        <div className={scrolled ? 'header-parent scrolled parent' : `header-parent parent`}>
            <div className="header-container container" data-aos="fade-down">
                <Link to="/" className="logo-box">
                    <div className="logo"></div>
                </Link>
                <div className="navigations ">
                    <Link to="/">Home</Link>
                    <Link to="/about">About Us</Link>
                    <Link className='menu'>Services <span className='golden-span'><IoMdArrowDropdown /></span>
                        <div className='sub-menu1'>
                            <Link to="/clinical-services">Clinical Services</Link>
                            <Link to="/medical-equipment">Medical Equipment</Link>
                            <Link to="/healthcare-strategy">Healthcare Strategy</Link>
                            <Link to="/healthcare-advocate">Healthcare Advocate</Link>
                            <Link to="/Medico-Legal">Medico-Legal</Link>
                            <Link to="/innovation&ai">Innovation & AI</Link>
                            <Link to="/loyalty-card">Loyalty Card</Link>
                        </div>
                    </Link>
                    <Link className='menu'>Medical & Wellness Tourism<span className='golden-span'><IoMdArrowDropdown /></span>
                        <div className='sub-menu'>
                            <Link to="/executive-cheak-ups">Executive Check-ups</Link>
                            <Link to="/dental-tourism">Dental Tourism</Link>
                            <Link to="/ivf-tourism">IVF Tourism</Link>
                            <Link to="/cosmatic-treatment">Cosmetics Treatment</Link>
                            <Link to="/electives-in-srilanka">Electives in Srilanka</Link>
                        </div>
                    </Link>
                    <Link to="/strawberry-facelift">Strawberry Facelift</Link>
                    <Link to="/blogs">Blogs</Link>
                    <Link to="/contact">Contact us</Link>

                </div>
                <div className={`${scrolled ? "hamburger-menu1" : "hamburger-menu"} ${toggle ? "hamburger-change" : ""}`} onClick={() => settoggle(!toggle)}>
                    <Hamburger toggled={toggle} />
                </div>
            </div>
            {toggle &&
                <div className="mobile_navbar">
                    <div className="mobile-navigations">
                        <Link to="/" onClick={() => settoggle(false)}>Home</Link>
                        <Link to="/about" onClick={() => settoggle(false)}>About Us</Link>
                        <div className='mobile-menu' onClick={() => setmob1(!mob1)}>Services <IoMdArrowDropdown />
                            <div className={mob1 ? "mobile-sub-menu" : "mobile-sub-menu2"}>
                                <Link to="/clinical-services" onClick={() => settoggle(false)}>Clinical Services</Link>
                                <Link to="/medical-equipment" onClick={() => settoggle(false)}>Medical Equipment</Link>
                                <Link to="/healthcare-strategy" onClick={() => settoggle(false)}>Healthcare Strategy</Link>
                                <Link to="/healthcare-advocate" onClick={() => settoggle(false)}>Healthcare Advocate</Link>
                                <Link to="/Medico-Legal" onClick={() => settoggle(false)}>Medico-Legal</Link>
                                <Link to="/innovation&ai" onClick={() => settoggle(false)}>Innovation & AI</Link>
                                <Link to="/loyalty-card" onClick={() => settoggle(false)}>Loyalty Card</Link>
                            </div>
                        </div>
                        <div className='mobile-menu' onClick={() => setmob2(!mob2)}>Medical & Wellness Tourism<IoMdArrowDropdown />
                            <div className={mob2 ? "mobile-sub-menu" : "mobile-sub-menu2"}>
                                {/* <Link to="/wellness" onClick={() => settoggle(false)}>Medical & Wellness Tourism</Link> */}
                                <Link to="/executive-cheak-ups" onClick={() => settoggle(false)}>Executive Check-ups</Link>
                                <Link to="/dental-tourism" onClick={() => settoggle(false)}>Dental Tourism</Link>
                                <Link to="/ivf-tourism" onClick={() => settoggle(false)}>IVF Tourism</Link>
                                <Link to="/cosmatic-treatment" onClick={() => settoggle(false)}>Cosmetics Treatment</Link>
                                <Link to="/electives-in-srilanka" onClick={() => settoggle(false)}>Electives in Srilanka</Link>
                            </div>
                        </div>
                        <Link to="/strawberry-facelift" onClick={() => settoggle(false)}>Strawberry Facelift</Link>
                        <Link to="/blogs" onClick={() => settoggle(false)}>Blogs</Link>
                        <Link to="/contact" onClick={() => settoggle(false)}>Contact us</Link>
                    </div>
                    <div className='test-menu' onClick={() => settoggle(!toggle)}>
                    <Hamburger toggled={toggle} />
                </div>
                </div>
            }

        </div>
    )
}

export default Header