import React, { useState } from 'react'
import HeroSection from '../components/Hero'
import "../styles/home.scss"
import SectionDividerCard from '../components/SectionDividerCard'
import AboutusCard from '../components/AboutusCard'
import Expertise from '../components/Expertise'
import WhyChoose from '../components/WhyChoose'
import Testimonial from '../components/Testimonial';
import trust from '../photos/home/Trust.webp'
import Faqs from '../components/Faqs'
import AOS from "aos";
import "aos/dist/aos.css";
import TestHero from '../data/TestHero';
import BgVideo from "../videos/dna.webm"
import OurServicesCarousel from '../components/OurServicesCarousel'
import {Helmet} from "react-helmet"
AOS.init({
  duration: 1200
});
const Home = () => {
  const accor = [
    {
      title: "High Quality Lab",
      content: "Our Team is distinguished not only due to our doctors focus on preventive treatment, but due to their extensive skills in case managing extremely complex and rare medical problems where even the UK private sector may not contribute."
    },
    {
      title: "Home Visits",
      content: "At the heart of how we deliver our medical care, and with good reason. Not only does it maintain continuity and that personal touch, it saves you the time and inconvenience of travelling and sitting in a crowded waiting room. Available 7 days a week, every day of the year."
    },
    {
      title: "Prescriptions",
      content: "Repeat or acute prescriptions can easily be arranged at your local pharmacy. And if we’re seeing you at home, we carry a full array of urgently needed drugs dispensed free to our members."
    },
    {
      title: "Nutrition and fitness",
      content: "We put passion and knowledge into our nutrition and fitness advisory services, By speaking with you, we get to know your unique needs"
    },
  ]
  const cardcontent =
    [
      {
        title: "Our Services",
        content: ["Clinical Services Medical", " Equipment/ Device Supply", "Healthcare Advocate", "Healthcare Strategy", "Medico – Legal"],
        image: "",

      },

      {
        title: "Our Facilities  ",
        content: ["  24/7 digital doctor access", " Urgent care, no waiting", " Priority appointments", " Healthcare advocate", " Follow-up office visits ", "Priority medical referrals"],
        image: "",
      },


      {
        title: "Medical Wellness & Tourism",
        content: ["Executive Health Checkups", "Dental Tourism", "IVF Tourism", "Cosmetic Treatments"],
        image: "",
      },
    ]

  return (
    <>
    <Helmet>
      <title>Healthcare Concierge - 24/7 Healthcare Services in the UK</title>
      <meta name="description" content="Experience comprehensive executive health checkups and explore medical wellness and tourism options. Join our loyalty program for exclusive discounts."  />
      <meta name="keywords" content="concierge healthcare, concierge medicine, medical concierge, concierge medical, medical care, primary care, primary care physician, health care, medical advice, primary health care, ai healthcare, health website, health care website " />
      <link rel="canonical" href="https://healthcareconcierge.net/" />
    </Helmet>
      <div className='homeparent parent'>
        {/* <HeroSection /> */}
        <TestHero />

        <div className="divider-container" data-aos="fade-up">
          <SectionDividerCard title="We are here to assist you" content="Please leave your contact details and we will get back to you." link="/contact" />
        </div>
        <div className="aboutus-container container">

          <AboutusCard />
        </div>


        <div className="home-experties">
          <div className="overlay4"></div>
          <video autoPlay loop muted className="bg-vid-4" style={{ position: "absolute", objectFit: "cover" }} height="100%" width="100%">
            <source src={BgVideo} type="video/mp4" />
          </video>

          {/* <Expertise title="Our Expertise" indicator="our expertise" card={cardcontent} /> */}
          <OurServicesCarousel />
        </div>
        <div className="whychooseus-container container trust-container ">
          <WhyChoose
            img={trust}
            indicator="why choose us" title="Why People Trust Us" content="Our team is distinguished not only due to our doctors focus on preventive treatment, but due to their extensive skills in case management of extremely complex and rare medical problems, where even the UK private sector may not contribute." accor={accor} />
        </div>

      </div>

      <div className="testimonial-container container">
        <div className="overlay5"></div>
        <video autoPlay loop muted className="bg-vid-2" style={{ position: "absolute", objectFit: "cover" }} height="100%" width="100%">
          <source src={BgVideo} type="video/mp4" />
        </video>
        <Testimonial />

        {/* <Expertise title="Why Choose Us" indicator="our expertise" card={cardcontent} />
        <div className="whychooseus-container container">
          <WhyChoose indicator="why choose us" title="Why People Trust Us" content="Our team is distinguished not only due to our doctors focus on preventive treatment, but due to their extensive skills in case managing, Extremely complex and rare medical problems, where even the UK private sector may not contribute." accor={accor} />
        </div> */}
        {/* <div className="testimonial-container container">
          <Testimonial />
        </div> */}
      </div>

      <div className="footer-before">
        <SectionDividerCard title="Devices" content="Explore our range of cosmetic devices for personalised skincare solutions." link="/contact" />
      </div>

    </>

  )
}

export default Home