import React, { useState } from 'react'
import "../styles/dental_tourism.scss";
import Faqs from '../components/Faqs.js'
import WhyChoose from '../components/WhyChoose'
import dental from '../photos/health/dental.webp';
import Headertop from '../components/Headertop.js';
function Dental_tourism() {
    const [faqstate,setfaqstate] = useState(3)
  const faq = [
      {
        title: "What is dental healthcare?",
        content: "A health check-up, a medical assessment or physical examination, is a comprehensive evaluation of an individual's overall health, including various tests and screenings."
      },
      {
        title: "Why is dental care necessary for overall health?",
        content: "Dental care is crucial for overall health because oral health is closely linked to systemic health. Good dental hygiene can prevent dental issues and reduce the risk of certain medical conditions."
      },
      {
        title: "Where can I find a dental care clinic?",
        content: "You can find dental care clinics in your area by searching online, asking for recommendations from friends and family, or contacting your local dental association."
      },
      {
        title: "What services are typically offered at a dental care clinic?",
        content: "Dental care clinics offer various services, including routine check-ups, cleanings, fillings, extractions, root canals, dental crowns, and orthodontic treatments."
      },
      {
        title: "How often should I get a health check-up?",
        content: "The frequency of health check-ups may vary depending on age, gender, and health status. It's best to consult with a healthcare provider for personalised recommendations."
      },
      {
        title: "Do dental care clinics accept dental insurance?",
        content: "Many dental care clinics accept dental insurance, but you should check with the clinic and your insurance provider to confirm coverage and network providers."
      },
      {
        title: "What is dental tourism?",
        content: "Dental tourism involves travelling to another country to receive dental care, often for cost savings or access to specialised treatments."
      },
      {
        title: "How can I maintain good dental health?",
        content: "Maintaining good dental health involves daily oral hygiene practices like brushing and flossing, regular dental check-ups, and a balanced diet low in sugary foods and beverages."
      },
      {
        title: "What is the role of a dental card?",
        content: "A dental card is a form of dental insurance or discount plan that helps individuals access dental care services at reduced costs."
      },
      {
        title: "Can dental care clinics provide both dental and medical services?",
        content: "Some dental care clinics may offer a combination of dental and medical services, particularly in cases where oral health is linked to medical conditions."
      },
      {
        title: "What is the difference between a dental care clinic and a dental clinic?",
        content: "A dental care clinic and a dental clinic are often used interchangeably and typically refer to the same type of facility providing dental services."
      },
      {
        title: "How often should I visit a dental care clinic for check-ups?",
        content: "It is generally recommended to visit a dental care clinic for routine check-ups and cleanings at least every six months, but the frequency may vary based on individual oral health needs."
      },
      {
        title: "What should I do if I have legal concerns related to my healthcare?",
        content: "If you have legal concerns related to your healthcare, consult with an attorney who specialises in healthcare law to address your specific issues."
      },
      {
        title: "What is the goal of quality dental care?",
        content: "Quality dental care aims to provide effective and compassionate dental services that improve and maintain oral health while ensuring patient comfort and satisfaction."
      },
     
      {
        title: "What should I do in case of a dental emergency?",
        content: "In a dental emergency, immediately contact a dental care clinic or seek medical attention if the situation is severe or involves injury."
      },
      {
        title: "How can I find a trusted and quality dental care clinic?",
        content: "To find a trusted and quality dental care clinic, read reviews, check credentials, ask for referrals, and schedule a consultation to discuss your needs and concerns."

      },
      {
        title: "Are there specialised dental clinics for specific treatments like orthodontics?",
        content: "There are specialised dental clinics for various treatments, including orthodontics, oral surgery, periodontics, and pediatric dentistry."
        
      },
      
    ]
    return (
        <>
    <Headertop title="Dental Tourism" desc="Dental Care Abroad with A Vacation"/>
            <div className="stresswhy-parent parent">
                <div className="stress-whychooseus-container" >
                    <WhyChoose
                    img={dental}
                        bg="var(--cream)"
                        indicator="why choose us"
                        title="Dental Tourism"
                        content="Dental tourism is expanding globally. With increasing interdependence and competition, advances in techniques, materials, and technology spread rapidly, enabling providers in developing countries to offer dental care at substantial cost savings compared to their counterparts in the developed world. A subset of medical tourism, dental tourism involves individuals seeking dental care outside their local healthcare systems, often combined with a vacation."

                    />
                </div>
            </div>
            <div className="faqs_paren10 parent">
        <div className="faqs_container10_home container">
          <h2>FAQ for Dental Tourism</h2>
          {
            faq.slice(0,faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
          {
            faq.length >= faqstate &&  <button className='btn-dark-blue loadmore-btn' onClick={()=>setfaqstate(faqstate + 3)}>Load More</button>
          } 
          </center>
      
         
        </div>
      </div>
        </>
    )
}

export default Dental_tourism