import React from 'react'
import "../styles/expertisecard.scss"
import { Link } from 'react-router-dom'
import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { FiArrowRight } from "react-icons/fi";
const ExpertiseCard = (props) => {
  console.log(props.content.map((data) => data))
console.log("index")
  console.log(props.delay)
  return (
    <div className='expertise-card' data-aos="fade-up"  data-aos-delay={props.delay * 300}>
      {props.image && <div className="expertise-img" style={{ background: `url(${props.image})` }}></div>}
      <div className="expertise-card-title">{props.title}</div>
      <div className="expertise-card-content">
        {props.description && <div className='content'>{props.description}</div>}
        {
          props.content && props.content.map((data, index) => (
            <div className='expertise-card-content-box' key={index}>
              <span className='golden-span'><IoCheckmarkCircleOutline /></span>
              {data}
            </div>
          ))
        }
        {/* {props.content1 && <div className='expertise-card-content-box'><span  className='golden-span'><IoCheckmarkCircleOutline /> </span>{props.content1}</div>}  */}

      </div>
      <div className="expertise-card-button">
        <Link to={props.link}>Read More <span><FiArrowRight /></span></Link>
      </div>
    </div>
  )
}

export default ExpertiseCard