import React, { useEffect, useState } from 'react';
import "../styles/blogs.scss";
import Headertop from '../components/Headertop';
import BlogCard from '../components/BlogCard';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { TextField, MenuItem } from '@mui/material';
import { FcSearch } from "react-icons/fc";
import { Helmet } from 'react-helmet';
const Blog = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [cat, setCat] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const postsPerPage = 6;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    useEffect(() => {
        fetchData();
        fetchCategory();
    }, []);

    const fetchData = () => {
        axios.get("https://www.api.healthcareconcierge.net/api/blogs/")
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
            })
            .catch(err => console.log(err));
    };

    const fetchCategory = () => {
        axios.get("https://www.api.healthcareconcierge.net/api/category/")
            .then(res => setCat(res.data))
            .catch(err => console.log(err));
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        filterData(event.target.value, selectedCategory);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        filterData(searchQuery, event.target.value);
    };

    const filterData = (query, category) => {
        let filteredPosts = data;

        if (query) {
            filteredPosts = filteredPosts.filter(post =>
                post.title.toLowerCase().includes(query.toLowerCase())
            );
        }

        if (category) {
            filteredPosts = filteredPosts.filter(post =>
                post.category.id === category
            );
        }

        setFilteredData(filteredPosts);
    };

    const handleClearFilters = () => {
        setSearchQuery('');
        setSelectedCategory('');
        setFilteredData(data);
        setCurrentPage(1);
    };
    return (
        <>
           <Helmet>
        <title>Blog - Stay Informed with Healthcare Concierge's Latest Articles</title>
        <meta name="description" content="Explore our collection of blogs at Healthcare Concierge. Read informative health articles, wellness tips, medical insights, and the latest healthcare news. Stay informed and educated about various healthcare topics to optimize your well-being." />
    </Helmet>
            <Headertop title="Blogs" desc="Explore insightful perspectives and engaging content."/>
            <div className="filter-parent parent">
                <div className="filter-container container">
                    <div className="filter-box">
                        <div className="input">
                            <span className='search-icon'>
                                <FcSearch />
                            </span>
                            <input type="text" placeholder='Search Your Blogs' value={searchQuery} onChange={handleSearchChange} />                           
                        </div>
                        <TextField
                            select
                            label="Category"
                            fullWidth
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            <MenuItem value="">All Categories</MenuItem>
                            {cat.map((data) => (
                                <MenuItem key={data.id} value={data.id}>
                                    {data.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <button className='btn-dark-blue reset-btn' onClick={handleClearFilters}>Clear</button>
                    </div>
                </div>
            </div>
            <div className="allblogsparent parent">
                <div className="allblogscontainer container">
                    {currentPosts.length > 0 ? (
                        currentPosts.map((post, index) => (
                            <BlogCard
                                id={post.id}
                                key={index}
                                date={post.date}
                                cat={post.category.name}
                                title={post.title}
                                content={post.blog_content}
                                url={post.image}
                            />
                        ))
                    ) : (
                        <p>No blogs to display.</p>
                    )}
                </div>
                {filteredData.length > postsPerPage && (
                    <div className="pagination-buttons">
                        <Stack spacing={2}>
                            <Pagination
                                count={Math.ceil(filteredData.length / postsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color='primary'
                            />
                        </Stack>
                    </div>
                )}
            </div>
        </>
    );
};

export default Blog;
