import React from 'react'
import "../styles/aboutmain.scss"
import SectionIndicator from './SectionIndicator'
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";
import { Tilt } from 'react-tilt'
import { Link } from 'react-router-dom';
AOS.init({
  duration: 1000
});

const AboutMain = (props) => {
  const defaultOptions = {
    reverse: false,  // reverse the tilt direction
    max: 35,     // max tilt rotation (degrees)
    perspective: 1000,   // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1,    // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000,   // Speed of the enter/exit transition
    transition: true,   // Set a transition on enter/exit.
    axis: null,   // What axis should be disabled. Can be X or Y.
    reset: true,    // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
  }
  return (
    <div className="about-main" style={{ flexDirection: `${props.direction}` }}>
      <div className="about-main-left" data-aos="fade-up">
        <Tilt options={defaultOptions} style={{ height: "fit-content", width: "fit-content" }}>
          <div className="about-main-left-img" style={{ background: `url(${props.img})` }}>

          </div>
        </Tilt>

      </div>
      <div className="about-main-right" data-aos="fade-down">
        <div className="about-main-heading title">
          {props.section_title}
          {props.founder_desc && <div className="founder-degree content">{props.founder_desc}</div>}
        </div>

        <div className="about-main-desc content">
          {props.content}
        </div>
        <div className="about-main-markbox">
          {props.mark && props.mark.map((data) => {
            return (<div className="markbox"><span className='golden-span'><IoCheckmarkCircleOutline /></span>{data}</div>
            )
          })}
        </div>
        <Link className='btn-dark-blue ' style={{marginTop:"20px"}} to="/contact">Enquire Now</Link>
      </div>
    </div>

  )
}

export default AboutMain