import React, { useEffect, useRef } from "react";
import "../styles/hero.scss";
import { Link } from "react-router-dom";
import SectionIndicator from "../components/SectionIndicator";
import AOS from "aos";
import "aos/dist/aos.css";
import { Tilt } from 'react-tilt'
import BgVideo from "../videos/test2.mp4";
import img1 from "../photos/hero/img1.webp"
import img2 from "../photos/hero/img2.webp"
import img3 from "../photos/hero/img3.webp"
import img4 from "../photos/hero/img4.webp"
AOS.init({
    duration: 1000
});

function TestHero() {
    const defaultOptions = {
        reverse: false,
        max: 35,
        perspective: 1000,
        scale: 1,
        speed: 1000,
        transition: true,
        axis: null,
        reset: true,
        easing: "cubic-bezier(.03,.98,.52,.99)",
    };
    return (
        <>
            <div className="hero-parent parent">
                <video autoPlay loop muted className="bg-vid" style={{ position: "absolute", objectFit: "cover" }} height="100%" width="100%">
                    <source src={BgVideo} type="video/mp4" />
                </video>
                <div className="overlay2"></div>
                <div className="hero-container container">
                    <div className="hero-left">
                        <div className="partner" data-aos="fade-right" data-aos-delay="200">
                            <SectionIndicator title="your partner in health" />
                        </div>
                        <h1 data-aos="fade-right" data-aos-delay="400">Holistic Care for Your Wellbeing</h1>
                        <p data-aos="fade-right" data-aos-delay="600" className="hero-p">
                            Experience the power of personalised healthcare concierge services that cater to your unique needs, ensuring exceptional care at every step.
                        </p>

                        <div className="cta-buttons" data-aos="fade-right" data-aos-delay="800">
                            <Link className="services-btn btn-dark-cream" to="/contact">Contact us</Link>
                        </div>
                    </div>

                    <div className="hero-right" data-aos="fade-up">
                        <div className="hero-right-box">
                            <div className="herobox1  herobox bg-img-cover" style={{background: `url(${img1})`}}></div>
                            <div className="herobox2 herobox">
                                <div className="hero-child-box hero-child-box1 bg-img-cover" style={{background: `url(${img2})`}}>

                                </div>
                            </div>
                            <div className="herobox3  herobox">
                                <div className="hero-child-box2 hero-child-box bg-img-cover" style={{background: `url(${img4})`}}>
                                </div>
                            </div>
                            <div className="herobox4 herobox bg-img-cover" style={{background: `url(${img3})`}}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TestHero;
