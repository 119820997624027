import logo from './logo.svg';
import './App.scss';
import Header from './components/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer';
import About from './pages/About';
import Services from './pages/Services';
import ClinicalServices from './pages/ClinicalServices';
import Medical_equipment from './pages/Medical_equipment';
import Healthcare from './pages/Healthcare';
import HealthAdvocate from './pages/HealthAdvocate';
import Medical_Wellness from './pages/Medical_wellness';
import Executive_health from './pages/Executive_health';
import Dental_tourism from './pages/Dental_tourism';
import Ivf_tourism from './pages/Ivf_tourism';
import Cosmatic_tourism from './pages/Cosmatic_tourism';
import MedicoL from './pages/MedicoL';
import Blog from './pages/Blog';
import Blogdetail from './pages/Blogdetail';
import Contact from './pages/Contact';
import Loyality from './pages/Loyality';
import ScrollToTop from './components/ScrollToTop';
import Cosmetic from './pages/Cosmetic';
import Electives from './pages/Electives';
import InnovativeAi from './pages/InnovativeAi';
import PopupButton from './tools/PopupButton';
import TermsAndConditions from './pages/Term';
import CookiePolicy from './pages/Privacy';
import ReactGA from "react-ga4";


ReactGA.initialize("G-6PGF2R7FQB");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

function App() {
  return (
    <BrowserRouter>
      <Header />
      <PopupButton/>
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='' element={<Services/>}/>
        <Route path='/clinical-services' element={<ClinicalServices/>}/>
        <Route path='/medical-equipment' element={<Medical_equipment/>}/>
        <Route path='/healthcare-strategy'  element={<Healthcare/>}/>
        <Route path='/healthcare-advocate' element={<HealthAdvocate/>}/>
        <Route path='/Medico-Legal' element={<MedicoL/>}/>
        {/* <Route path='/wellness' element={<Medical_Wellness/>}/> */}
        <Route path='/executive-cheak-ups' element={<Executive_health/>}/>
        <Route path='/dental-tourism' element={<Dental_tourism/>}/>
        <Route path='/ivf-tourism' element={<Ivf_tourism/>}/>
        <Route path='/cosmatic-treatment' element={<Cosmatic_tourism/>}/>
        <Route path='/contact' element={<Contact />} />
        <Route path='/blogs' element={<Blog />} />
        <Route path='/blogs/:id' element={<Blogdetail />} />
        <Route path='/loyalty-card' element={<Loyality />} />
        <Route path='/strawberry-facelift' element={<Cosmetic />} />
        <Route path='/electives-in-srilanka' element={<Electives />} />
        <Route path='/innovation&ai' element={<InnovativeAi />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-policy' element={<CookiePolicy />} />

      </Routes>
      <Footer />
   

    </BrowserRouter>

  );
}

export default App;
