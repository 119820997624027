import "../styles/card_section.scss";
import { Link } from "react-router-dom";
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import BgVideo from "../videos/med2.webm"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
function Card_section(props) {
    const [limit, setlimit] = useState(200)
    return (
        <>
            <div className="card-parent parent" >
                <div className="overlay5"></div>
                <video autoPlay loop muted className="bg-vid-2" style={{ position: "absolute", objectFit: "cover" }} height="100%" width="100%">
                    <source src={BgVideo} type="video/mp4" />
                </video>
                <div className="card-container container">
                    <div className="card-content-box">
                        <h2 className="card-title">
                            {props.heading}
                        </h2>
                    </div>
                    <div className="card-box">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={3}
                            centeredSlides={false}
                            // autoplay={{
                            //     delay: 2500,
                            //     disableOnInteraction: false,
                            // }}
                            pagination={{
                                clickable: true,
                            }}

                            breakpoints={{


                                300: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },

                                820: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1250: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="card_swiper"
                        >
                            {props.card1 && <SwiperSlide>
                                <div className="card about-card" style={{ background: `${props.bg}`, height: `${props.height}` }}>
                                    <div className="content-box">
                                        <div className="img-main-card bg-img-cover" style={{ background: `url(${props.img})` }}>
                                        </div>
                                        <h3 className="card-headding">{props.card1}</h3>
                                        
                                        {props.card1para && <p className="card-inner-para">{props.card1para.slice(0, limit)}{limit != 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(1000)}>...Read more </span>}
                                            {limit == 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(200)}>Read less </span>}
                                        </p>
                                        }
                                        <ul>
                                            {props.list && props.list.map((item) => {
                                                return (

                                                    <li><span><IoMdCheckmarkCircleOutline /></span>{item}</li>

                                                )
                                            })}
                                        </ul>
                                    </div>
                                    {props.btntext && (
                                        <Link to="/social_media_support" className="btn button btn-dark-blue ">
                                            {props.btntext}
                                        </Link>
                                    )}
                                </div>



                            </SwiperSlide>}
                            {props.card2 && <SwiperSlide>
                                <div className="card about-card" style={{ background: `${props.bg}`, height: `${props.height}` }}>
                                    <div className="content-box">
                                        <div className="img-main-card bg-img-cover" style={{ background: `url(${props.img1})` }}>

                                        </div>
                                        <h3 className="card-headding">{props.card2}</h3>
                                        {props.card2para && <p className="card-inner-para">{props.card2para.slice(0, limit)}{limit != 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(1000)}>...Read more </span>}
                                            {limit == 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(200)}>Read less </span>}
                                        </p>
                                        }
                                        <ul>
                                            {props.list1 && props.list1.map((item) => {
                                                return (

                                                    <li><span><IoMdCheckmarkCircleOutline /></span>{item}</li>

                                                )
                                            })}
                                        </ul>
                                    </div>
                                    {props.btntext && (
                                        <Link to="/social_media_support" className="btn-dark-blue">
                                            {props.btntext}
                                        </Link>
                                    )}
                                </div>
                            </SwiperSlide>}

                            {props.card3 && <SwiperSlide>
                                <div className="card about-card" style={{ background: `${props.bg}`, height: `${props.height}` }}>
                                    <div className="content-box">
                                        <div className="img-main-card bg-img-cover" style={{ background: `url(${props.img2})` }}>

                                        </div>
                                        <h3 className="card-headding">{props.card3}</h3>
                                        {props.card3para && <p className="card-inner-para">{props.card3para.slice(0, limit)}{limit != 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(1000)}>...Read more </span>}
                                            {limit == 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(200)}>Read less </span>}
                                        </p>
                                        }
                                        <ul>
                                            {props.list2 && props.list2.map((item) => {
                                                return (

                                                    <li><span><IoMdCheckmarkCircleOutline /></span>{item}</li>

                                                )
                                            })}
                                        </ul>
                                    </div>
                                    {props.btntext && (
                                        <Link to="/social_media_support" className="btn-dark-blue">
                                            {props.btntext}
                                        </Link>
                                    )}
                                </div>
                            </SwiperSlide>}
                            {props.card4 && <SwiperSlide>
                                <div className="card about-card" style={{ background: `${props.bg}`, height: `${props.height}` }}>
                                    <div className="content-box">
                                        <div className="img-main-card bg-img-cover" style={{ background: `url(${props.img3})` }}>

                                        </div>
                                        <h3 className="card-headding">{props.card4}</h3>
                                        {props.card4para && <p className="card-inner-para">{props.card4para.slice(0, limit)}{limit != 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(1000)}>...Read more </span>}
                                            {limit == 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(200)}>Read less </span>}
                                        </p>
                                        }
                                        <ul>
                                            {props.list3 && props.list3.map((item) => {
                                                return (

                                                    <li><span><IoMdCheckmarkCircleOutline /></span>{item}</li>

                                                )
                                            })}
                                        </ul>
                                    </div>
                                    {props.btntext && (
                                        <Link to="/social_media_support" className="btn-dark-blue">
                                            {props.btntext}
                                        </Link>
                                    )}
                                </div>
                            </SwiperSlide>}
                            {props.card5 && <SwiperSlide>
                                <div className="card about-card" style={{ background: `${props.bg}`, height: `${props.height}` }}>
                                    <div className="content-box">
                                        <div className="img-main-card bg-img-cover" style={{ background: `url(${props.img4})` }}>

                                        </div>
                                        <h3 className="card-headding">{props.card5}</h3>
                                        {props.card5para && <p className="card-inner-para">{props.card5para.slice(0, limit)}{limit != 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(1000)}>...Read more </span>}
                                            {limit == 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(200)}>Read less </span>}
                                        </p>
                                        }
                                    </div>
                                    {props.btntext && (
                                        <Link to="/social_media_support" className="btn">
                                            {props.btntext}
                                        </Link>
                                    )}
                                </div>
                            </SwiperSlide>}
                            {props.card6 && <SwiperSlide>
                                <div className="card about-card" style={{ background: `${props.bg}`, height: `${props.height}` }}>
                                    <div className="content-box">
                                        <div className="img-main-card bg-img-cover" style={{ background: `url(${props.img5})` }}>

                                        </div>
                                        <h3 className="card-headding">{props.card6}</h3>
                                        {props.card6para && <p className="card-inner-para">{props.card6para.slice(0, limit)}{limit != 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(1000)}>...Read more </span>}
                                            {limit == 1000 && <span style={{ color: "var(--dark-blue)" }} onClick={() => setlimit(200)}>Read less </span>}
                                        </p>
                                        }
                                    </div>
                                    {props.btntext && (
                                        <Link to="/social_media_support" className="btn">
                                            {props.btntext}
                                        </Link>
                                    )}
                                </div>
                            </SwiperSlide>}

                        </Swiper>

                    </div>

                </div>
            </div>

        </>
    );
}

export default Card_section;