import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/loyality.scss';
import SectionIndicator from '../components/SectionIndicator';
import { FiChevronsRight } from "react-icons/fi";
import { MdEuro } from "react-icons/md";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Headertop from '../components/Headertop';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Helmet } from 'react-helmet';

function Loyality() {
    return (
        <>
        <Helmet>
      <title>Loyalti card - Premium Healthcare in the UK</title>
      <meta name="description" content="Your trusted destination for medico-legal cases. From accidents to legal guidance, get VIP healthcare treatment and expert support."  />
      <meta name="keywords" content="Ai, Innovation, healthcare concierge, Artificial Intelligence, medical journey, healthcare services, healthcare it services, " />
      <link rel="canonical" href="https://healthcareconcierge.net/loyalty-card" />
    </Helmet>
            <Headertop title="Loyalty Card" desc="Get rewarded for your loyalty"/>
            <div className="layality-parent parent">
                <div className="layality-container container">

                    <h3 className='subheaddig'> <span style={{fontWeight: "300"}}>Join the club and enjoy exclusive discounts and offers with </span><br/> Healthcare Concierge Loyalty Card

                    </h3>


                    <div className="loyality-card-container">

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={50}
                            centeredSlides={false}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}

                            breakpoints={{

                                340: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                700: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1100: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}

                            navigation={true}
                            modules={[Autoplay, Navigation]}
                            className="lmySwiper"
                        >
                            <SwiperSlide className="loyality-card">

                                    <div className="lcard-img1 bg-img-cover"></div>
                                    <div className="lcard-content">
                                        <h3 className="lcard-title">Personal</h3>
                                        <h3 className="lcard-price"><span className='euro'><MdEuro /></span>100</h3>
                                        <p>Free Consultation for Medical 8, wellness Tourism from the top as :</p>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p>General Physician</p></div>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p> Dentist</p></div>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p> Cosmetic physician</p></div>

                                        <Link to='/contact' className='btn-dark-blue l-btn'>Enquire Now</Link>
                                    </div>
                                

                            </SwiperSlide>

                            <SwiperSlide className="loyality-card">

                                    <div className="lcard-img2 bg-img-cover"></div>
                                    <div className="lcard-content">
                                        <h3 className="lcard-title">Family</h3>
                                        <h3 className="lcard-price"><span className='euro'><MdEuro /></span>150</h3>
                                        <p>Free Consultation for Medical E-wellness Tourism from top :</p>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p>General Physician</p></div>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p> Dentist</p></div>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p> Cosmetic physician</p></div>

                                        <Link to='/contact' className='btn-dark-blue l-btn'>Enquire Now</Link>
                                    </div>
                              

                            </SwiperSlide>


                            <SwiperSlide className="loyality-card">

                                    <div className="lcard-img3 bg-img-cover"></div>
                                    <div className="lcard-content">
                                        <h3 className="lcard-title">Commercial</h3>
                                        <h3 className="lcard-price"><span className='euro'><MdEuro /></span>250</h3>
                                        <p>Upto 10 employees Free Consultation for Medical & wellness Tourism top as :</p>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p>General Physician</p></div>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p> Dentist</p></div>
                                        <div className="icon-box"><span className='licon'><FiChevronsRight /></span><p> Cosmetic physician</p></div>
                                        <Link to='/contact' className='btn-dark-blue l-btn'>Enquire Now</Link>
                                    </div>
                               

                            </SwiperSlide>
                        </Swiper>


                    </div>



                </div>

            </div>


            <div className="loyality-baner-parent parent bg-img-cover">
                <div className="overlay"></div>
                <div className="loyality-baner-cintainer container">
                    <h2 className='banner-title'>Point collection</h2>
                    <p className='banner-text'>For every £1000 spent, users will receive 10 loyalty points, equivalent to £1.</p>
                    <p className='banner-txt'><Link to='/Medical_wellness' className='banner-link'>Health And Wellness Tourism </Link>Special</p>
                </div>

            </div>





        </>





    )
}

export default Loyality