import React from 'react'
import "../styles/about.scss"
import AboutMain from '../components/AboutMain'
import AboutDivider from '../components/AboutDivider'
import medicine from "../photos/About us/coierge-m.webp";
import founder from "../photos/About us/healthcare_leader.webp";
import countup from "../photos/About us/CT.webp";
import Headertop from '../components/Headertop';
import BgVideo from "../videos/dna.webm"
import smodi from "../photos/About us/sanjaymodi.jpg"
import {Helmet} from "react-helmet"
const About = () => {
  return (
    <>
    <Helmet>
      <title>About Us - Concierge Medicine with Functional Medicine</title>
      <meta name="description" content="At Healthcare Concierge, we are dedicated to  traditional concierge medicine through personalized healthcare services and a focus on Functional Medicine."  />
      <meta name="keywords" content="healthcare services, healthcare concierge, healthcare uk, medical concierge, healthcare it services, healthcare app, health service, medical medical insurance, health care system in the uk, health help, cares health " />
      <link rel="canonical" href="https://healthcareconcierge.net/about" />
    </Helmet>
    <Headertop title="About Us" desc="Integrating Care in Functional Medicine"/>
      <div className="about-parent">
        <div className="parent">
          <div className="container">
            <AboutMain img={medicine} indicator_title="about us" section_title="Personalised Care and Peace of Mind" content="Concierge membership isn’t just an investment in your physical health—it’s an investment in your quality of life. With 24/7 personal access to your physician and a healthcare advocate devoted entirely to your assistance, you will never have to worry about life’s unplanned events… at least about your health."
            mark={[
              "24/7 Digital Doctor Access",
              "Urgent Care, No Waiting",
              "Priority Appointments",
              "Healthcare Advocate",
              "Follow-Up Office Visits",
              "Priority Medical Referrals",
              ]} />
          </div>
        </div>
        <div className="about-divider-parent parent">
        <div className="overlay5"></div>
          <video autoPlay loop muted className="bg-vid-2" style={{ position: "absolute", objectFit: "cover"}} height="100%" width="100%">
            <source src={BgVideo} type="video/mp4" />
          </video>
          <div className="container">
            <AboutDivider img={countup} />
          </div>
        </div>
        <div className="about-divider-founders parent">
          <div className="about-divider-founder-container container">
            <div className='founder-title title'>Healthcare Concierge Leaders</div>
            <div className="founder-desc content">
            Patient care stemmed from personal experiences
            </div>
            <div className="founders-box">
              <AboutMain img={smodi} indicator_title="about us" section_title="Dr. Sanjay P.Mody" content="Dr. Sanjay P. Mody's academic journey spans continents and disciplines. Beginning with a BSc Hons in Anatomical Sciences from the University of Dundee, Scotland, he pursued further studies in health administration with an MHSA from Maine, USA. His medical education took him to the Cayman Islands and the USA, where he completed pre-clinical and clinical rotations across several states. During his time in the UK, Dr. Mody served as a clinical attaché on Harley Street while earning a mini-MBA at the Royal Society of Medicine. His extensive career in corporate and international healthcare has taken him to India, Dubai, the Cayman Islands, and the USA. Notably, he has participated in strategic dialogues between India, Singapore, and the UK government. Passionate about medical education, Dr. Mody has excelled as a teacher and served as a medical director for various international companies. With a keen interest in NHS law and GMC matters, he continues to enhance his skills and contribute to the field of medicine." founder_desc="MD MHSA (USA) BSc Hons (UK)" />
            </div>
          </div>
        </div>


      </div>
    </>
  )
}

export default About