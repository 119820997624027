import React from 'react'
import Headertop from '../components/Headertop'
import WhyChoose from '../components/WhyChoose'
import img from "../photos/ai/ai1.webp"
import img1 from "../photos/ai/ai2.webp"
import "../styles/innovation.scss"
import AboutMain from '../components/AboutMain'
import { Helmet } from 'react-helmet';
const InnovativeAi = () => {
    const accor = [
        {
            title: "Precision Diagnosis, Globally Delivered",
            content: " AI analyses your scans with unprecedented accuracy, identifying possible concerns before they become problems. Healthcare Concierge guarantees that you have access to these cutting-edge diagnostics, regardless of your location."
        },
        {
            title: "Fast-Tracking Drug Discovery Across the World",
            content: "The days of waiting years for breakthrough therapies are over. AI is hastening the discovery of life-saving treatments, and Healthcare Concierge stays ahead of the curve, linking you to potential discoveries that could improve your life, no matter where you are in the globe."
        },
        {
            title: "Your Personalised Treatment Plan",
            content: "AI analyses your medical history, DNA, and lifestyle to create personalised treatment plans. Healthcare concierge operates as your advocate, ensuring you receive care that is tailored to your individual needs while harnessing the most recent AI insights from across the world."
        },
        {
            title: "Clinical Trials on Hyperdrive",
            content: "AI accelerates clinical trials, providing potential therapies to you faster, regardless of location. Healthcare Concierge keeps you up to date on these opportunities allowing you to have access to cutting-edge medical care, no matter where you are."
        },
        {
            title: "Robotic Surgery Gets Smarter, Globally Available",
            content: "AI improves robotic surgery, reducing invasive procedures while increasing favorable outcomes. Healthcare Concierge connects you with top medical centers using these cutting-edge procedures, regardless of their location."
        },
        {
            title: "24/7 Health AI Assistant for Your Language",
            content: "Need answers at any time or from any location? AI-powered chatbots function as your personal health concierge, providing immediate access to information and help in your preferred language. At Healthcare Concierge, we add a human touch by providing personalised assistance and support throughout your healthcare journey, anywhere in the world."
        },
    ]
    return (
        <>
         <Helmet>
      <title>Innovation and AI - Premium Healthcare in the UK</title>
      <meta name="description" content="Your trusted destination for medico-legal cases. From accidents to legal guidance, get VIP healthcare treatment and expert support."  />
      <meta name="keywords" content="Ai, Innovation, healthcare concierge, Artificial Intelligence, medical journey, healthcare services, healthcare it services, " />
      <link rel="canonical" href="https://healthcareconcierge.net/innovation&ai" />
    </Helmet>
            <Headertop title="innovation and AI" desc="Pioneering AI-driven healthcare solutions for a healthier tomorrow."/>
            <div className="innovation-parent parent">
                <div className="innovation-container container">
                    <WhyChoose
                        img={img}
                        indicator="why choose us" title="Your Health Co-Pilot: AI-powered Insights at Healthcare Concierge" content="At Healthcare Concierge, we're passionate about leveraging the latest advancements to empower patients and elevate the healthcare experience. One of the most transformative forces shaping medicine today is Artificial Intelligence (AI).
                        Imagine a future where:
                        " accor={accor} />
                </div>
            </div>
            <div className="innovation-about-parent parent">
                <div className="innovation-about-container container">
                <AboutMain img={img1} 
                direction="row-reverse" section_title="Healthcare 2.0: AI is Revolutionising Your Medical Journey" content="The collaboration between medical research and artificial intelligence is ushering in a new era of healthcare. It promises faster diagnosis, more effective treatments, and, eventually, a healthier future for everyone. As AI technology advances, we at Healthcare Concierge may expect even more innovative advancements that will reshape the future of medicine. Allow us to guide you through this exciting future and provide you the tools you need to make educated health decisions." />
                </div>
            </div>
        </>
    )
}

export default InnovativeAi