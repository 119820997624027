import React from 'react'
import "../styles/headertop.scss"
import BgVideo from "../videos/headertop.webm"
const Headertop = (props) => {
  return (
    <div className="healthcare-parent parent">
      <video autoPlay loop muted className="bg-vid" style={{ position: "absolute", objectFit: "cover" }} width="100%" height="100%">
        <source src={BgVideo} type="video/mp4" />
      </video>
      <div className="overlay3"></div>
      <div className="healthcare-container container">
        <div className="healthcare-title">
          <h2>
            {
              props.title
            }</h2>
          {props.desc && <p className='header-top-desc'>
            {props.desc}</p>}

        </div>
      </div>
    </div>

  )
}

export default Headertop