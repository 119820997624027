import React from 'react'
import "../styles/expertise.scss"
import SectionDividerCard from './SectionDividerCard'
import SectionIndicator from './SectionIndicator'
import ExpertiseCard from './ExpertiseCard'
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
AOS.init({
    duration: 1000
});
const Expertise = (props) => {
    return (
        <div className="expertise-parent parent">
            <div className="expertise-container container">
                <p className='title' data-aos="fade-down">{props.title}</p>
                <div className="card-section" >
                            {
                                props.card && props.card.map((data, index) => {
                                    return (

                                        <ExpertiseCard title={data.title} delay={index}
                                            content={data.content} image={data.image} description={data.description}
                                        />

                                    )

                                })
                            } 
                </div>
            </div>
        </div>
    )
}

export default Expertise