import React, { useState } from 'react'
import Faqs from '../components/Faqs.js'
import "../styles/healthAdvocate.scss";
import WhyChoose from '../components/WhyChoose'
import Card_section from '../components/Card_section';
import img from "../assets/dr2.jpg";
import advo from "../photos/advocate/advoacte.webp";
import vip from "../photos/advocate/vip.webp";
import inter from "../photos/advocate/inter.webp";
import hca from '../photos/advocate/hca.webp';
import c1 from '../photos/advocate/c1.webp'
import c2 from '../photos/advocate/c2.webp'
import c3 from '../photos/advocate/c3.webp'
import Headertop from '../components/Headertop.js';
import { Helmet } from 'react-helmet';

function HealthAdvocate() {
    const [faqstate,setfaqstate] = useState(3)
  const faq = [
      {
        title: "      What is a care and support assistant?",
        content: "A care and support assistant is a trained professional who provides assistance and support to individuals, often in their homes or care facilities, to help with daily activities and improve their quality of life."
      },
      {
        title: "What services do care and support assistants provide?",
        content: "Care and support assistants offer a range of services, including personal care, medication management, companionship, meal preparation, and assistance with activities of daily living (ADLs)."
      },
      {
        title: "What is home and health care services?",
        content: "Home and health care services encompass a variety of healthcare and support services delivered in a home setting to individuals who may need medical care, assistance, or rehabilitation."
      },
      {
        title: "How can I find in-home care assistance for myself or a loved one?",
        content: "You can find in-home care assistance by contacting home care agencies, consulting with healthcare professionals, or seeking recommendations from friends and family."
      },
      {
        title: "What are the advantages of purchasing used healthcare equipment?",
        content: "Buying used healthcare equipment can be cost-effective, allowing healthcare facilities to save money while still maintaining quality patient care."
      },
      {
        title: "What is the difference between home health care and personal care services?",
        content: "Yes, medical equipment and supplies are regulated by government agencies such as the FDA in the United States to ensure safety, effectiveness, and quality standards."
      },
      {
        title: "Are there specialised services like dementia care available?",
        content: "Yes, many home care agencies offer specialised services, including dementia care, to cater to the unique needs of individuals with specific conditions"
      },
      {
        title: "How do I choose the best home care services for my needs?",
        content: "To choose the best home care services, consider factors like the agency's reputation, staff qualifications, services offered, and compatibility with your care preferences."
      },
      {
        title: "What is the role of a care home assistant?",
        content: "A care home assistant provides support and care to residents in care homes or assisted living facilities, assisting with daily tasks and ensuring their wellbeing."
      },
      {
        title: "Can home care services include assistance with transportation?",
        content: "Yes, many home care services offer transportation assistance to help clients get to medical appointments, run errands, or engage in social activities."
      },
      {
        title: "How can I access personal support services for a disabled family member?",
        content: "You can access personal support services for a disabled family member by contacting local disability support organisations, social services, or home care agencies specializing in disability care."
      },
      {
        title: "Are home care services available 24/7?",
        content: "Many home care agencies offer 24/7 care services to accommodate the needs of individuals who require round-the-clock assistance."
      },
      {
        title: "What is respite care, and how can it benefit caregivers?",
        content: "Respite care provides temporary relief to primary caregivers, allowing them to take a break while a care and support assistant takes over caregiving responsibilities."
      },
      {
        title: "How can I pay for home care services?",
        content: "Payment options for home care services may include private pay, long-term care insurance, Medicaid, and other government assistance programs. Consult with a financial advisor or the home care agency for guidance."
      },
     
      {
        title: "Are care and support assistants trained and certified?",
        content: "Yes, care and support assistants typically undergo training and certification programs to ensure they have the necessary skills and knowledge to provide quality care."
      },
      {
        title: "How can I get started with home care services or find a care and support assistant?",
        content: "To get started with home care services, contact local home care agencies, discuss your needs, and schedule an assessment to determine the level of care required."
      },
    ]

  return (
    <>
     <Helmet>
      <title>Healthcare Advocate - Premium Healthcare in the UK</title>
      <meta name="description" content="Empowering you to navigate the healthcare landscape with confidence. Discover tailored solutions, advocacy, and support for your healthcare journey."  />
      <meta name="keywords" content="healthcare services, healthcare it services, healthcare app, health service, healthcare system, Healthcare Concierge, Healthcare Advocate " />
      <link rel="canonical" href="https://healthcareconcierge.net/healthcare-advocate" />
    </Helmet>
    <Headertop title="Healthcare Advocate" desc="Guide through your healthcare needs"/>

            <div className="advoacte-parent parent">
            <div className="advoactechooseus-container" >
                    <WhyChoose
                    img={advo}
                    bg="var(--cream)"
                    indicator="why choose us"
                    title="Personal Healthcare Advocacy"
                    content="At Healthcare Concierge, we provide accurate information, advice, and support for your healthcare needs. With us, navigating the healthcare system becomes effortless, guaranteeing the best possible outcomes. Your personal Healthcare Advocate is dedicated to managing every aspect of your wellness journey, from booking appointments to coordinating procedures. They handle all details, including scheduling, finding specialists, and optimising care coordination, so you can focus on your health."
                   
                     />
            </div>
        </div>
        <div className="advocatee-parent parent">
            <div className="advocatee-chooseus-container" >
                    <WhyChoose
                    img={hca}
                    bg="var(--cream)"
                    indicator="why choose us"
                    title="Healthcare Advocate"
                    content="At Healthcare Concierge, we provide accurate information, advice, and support for your healthcare needs. Our team assists in understanding policies, procedures, and rights to ensure optimal care access. With a personal Healthcare Advocate, navigating the system becomes easier. From appointment booking to procedure coordination, your advocate handles every detail for a streamlined experience."
                    direction="row-reverse"
                   
                     />
            </div>
        </div>
        <div className="advocatee1-parent parent">
            <div className="advocatee1-chooseus-container" >
                    <WhyChoose
                    img={vip}
                    bg="var(--cream)"
                    indicator="why choose us"
                    title="VIP Treatment"
                    content="Experience the epitome of personalised care with our VIP treatment. Enjoy minimal wait times, tailor-made care plans, priority appointments with physicians, and streamlined diagnostic scheduling. From the moment you step in, you'll be welcomed to a world of unparalleled service and attention to detail. Our luxurious red-carpet treatment is just the beginning of your journey toward optimal health and wellbeing."
                    direction=""
                   
                     />
            </div>
        </div>
        <div className="advocateee1-parent parent">
        <div className="advocateee1-whychooseus-container" >
        
                <Card_section 
                mainbg=""
                heading="Discover a Range of Services​"
                img={c1}
                img1={c2}
                img2={c3}
                bg="var(--cream)"
                height="580px"
                card1="Diagnostics in the Comfort of Your Home"
                card2="Expedited Emergency Room Care"
                card3="Extensive Network of Physicians"
                card1para="Home visits by our physicians offer more than just consultations. Our team arrives fully equipped to provide comprehensive care, including diagnostics and lab work, all within the convenience of your own home."
                card2para="In the event of a serious emergency requiring a trip to the emergency room, your physician will meet you there to expedite the process, ensuring prompt attention and care."
                card3para="Access our global network of dedicated physicians and healthcare professionals committed to delivering the highest standard of medical care. With worldwide coverage and a constantly expanding network, we ensure you receive top-quality healthcare wherever you are."
                
                          />
                
        </div>
    </div>
    <div className="advocatee2-parent parent">
            <div className="advocatee2-chooseus-container" >
                    <WhyChoose
                    img={inter}
                    bg="var(--cream)"
                    indicator="why choose us"
                    title="International Care"
                    content="Healthcare concierge and consulting provides concierge-style healthcare services to members who are travelling or residing away from their primary care physician for an extended period. Our in-network physician team ensures members have immediate access to qualified medical care, delivered with comfort and privacy, whether in a hotel room, temporary residency, or home."
                    direction="row-reverse"
                   
                     />
            </div>
        </div>
        <div className="faqs_parent4 parent">
        <div className="faqs_container4_home container">
          <h2>FAQ for Healthcare Advocate</h2>
          {
            faq.slice(0,faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
          {
            faq.length >= faqstate &&  <button className='btn-dark-blue loadmore-btn' onClick={()=>setfaqstate(faqstate + 3)}>Load More</button>
          } 
          </center>
      
         
        </div>
      </div>

    
    
    
    </>
  )
}

export default HealthAdvocate