import React, { useState } from 'react'
import "../styles/contact.scss"
import { RxCross1 } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import Headertop from '../components/Headertop';
import axios from 'axios';
import { TextField } from '@mui/material';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';
const Contact = () => {
  const [formdata, setformdata] = useState({
    'name': "",
    "email": '',
    "contact": '',
    "message": ""
  })
  const submitHandler = (e) => {
    e.preventDefault();
    const username = 'developer_id';
    const password = 'developer@2024';
    axios.post("https://www.api.healthcareconcierge.net/api/contacts/", formdata, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        setOpen(true);
        setformdata({
          'name': "",
          "email": '',
          "contact": '',
          "message": ""
        });
      })
      .catch(error => {
        console.error('Error:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
        }
      });
  }
  // 
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Helmet>
        <title>Contact Us - Get in Touch with Healthcare Concierge</title>
        <meta name="description" content="Get in touch with Healthcare Concierge today! Contact us for any inquiries or to book our healthcare services. Reach out to our dedicated team." />
    </Helmet>
      <Headertop title='Contact' />
      <div className="contact_wrapper parent">
        <div className="contact_main container ">
          <div className="contact_page_left">
            <h2>Contact Information</h2>
            <div className="left_contact_page_box">
              <div className="left_inner_box">
                <span>
                  <IoIosCall />
                </span>
                <h3>+44 78348 99488</h3>
              </div>
              <div className="left_inner_box">
                <span>
                  <MdOutlineEmail />
                </span>
                <h3>info@healthcareconcierge.net</h3>
              </div>
              <div className="social_icons_box">
                <Link to='https://www.facebook.com/Health.Care.Concierge.London/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0' target='_blank'>
                  <FaFacebookF />
                </Link>
                <Link to='https://www.instagram.com/healthcare.concierge/?igshid=MzRlODBiNWFlZA%3D%3D' target='_blank'>
                  <FaInstagram />
                </Link>
                <Link to='https://www.linkedin.com/company/healthcare-concierge-london/' target='_blank'>
                  <FaLinkedinIn />
                </Link>
              </div>
            </div>
          </div>
          <div className="contact_page_right">
            <div className="form_box">
              <form action="" onSubmit={submitHandler}>

                <TextField value={formdata.name} required id="filled-basic" label="Full Name" variant="outlined" onChange={(e) => setformdata({ ...formdata, 'name': e.target.value })} fullWidth />
                <TextField value={formdata.email} required id="filled-basic" type='email' label="Email" onChange={(e) => setformdata({ ...formdata, 'email': e.target.value })} variant="outlined" fullWidth />
                <TextField value={formdata.contact} required id="filled-basic" type='number' label="Contact" onChange={(e) => setformdata({ ...formdata, 'contact': e.target.value })} variant="outlined" fullWidth />
                <TextField value={formdata.message} id="filled-basic" type='text' label="Message" multiline onChange={(e) => setformdata({ ...formdata, 'message': e.target.value })} variant="outlined" fullWidth rows={6} />

                <button className='btn-dark-blue' type='submit'>Send Message</button>
              </form>


            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
          key={'bottom' + 'right'}
        >
          Message sent!
        </MuiAlert>
      </Snackbar>
    </>
  )
}

export default Contact