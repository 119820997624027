import React from 'react'
import "../styles/whychoose.scss"
import SectionIndicator from './SectionIndicator'
import Accordian from './Accordian'
import AOS from "aos";
import "aos/dist/aos.css";
import { Tilt } from 'react-tilt';
import { Link } from 'react-router-dom';
AOS.init({
    duration: 1000
});
const WhyChoose = (props) => {
    const defaultOptions = {
        reverse: false,  // reverse the tilt direction
        max: 35,     
        perspective: 1000, 
        scale: 1,    // 2 = 200%, 1.5 = 150%, etc..
        speed: 1000,   
        transition: true,   
        axis: null,   
        reset: true,    
        easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }
    return (
        <div className="whychoose-parent container" style={{ flexDirection: `${props.direction}` }}>
            <div className="whychoose-left" data-aos="fade-up">
                <Tilt options={defaultOptions} style={{ height: "fit-content", width: "fit-content" }}>
                    <div className="whychoose-img" style={{ background: `url(${props.img})` }}></div>
                </Tilt>
            </div>
            <div className="whychoose-right" data-aos="fade-down">
                {/* <SectionIndicator title={props.indicator} /> */}
                <p className='whychoose-header'>{props.title}</p>
                <p className='whychoose-content'>{props.content}</p>
                <div className="whychooseaccordian">
                    {props.accor && props.accor.map((item, index) => (
                        <Accordian key={index} title={item.title} content={item.content} list={item.list} />
                    ))}
                </div>
                <Link className='btn-dark-blue' to="/contact">Contact Us</Link>
            </div>
        </div>
    )
}

export default WhyChoose