import React,{ useEffect, useRef } from "react";
import "../styles/hero.scss";
import { Link } from "react-router-dom";
import SectionIndicator from "./SectionIndicator";
import AOS from "aos";
import "aos/dist/aos.css";
import Spline from '@splinetool/react-spline';
import { Tilt } from 'react-tilt'
import video from "../videos/test2.mp4"
import ReactPlayer from 'react-player/lazy'
AOS.init({
  duration: 1000
});
function HeroSection() {
  const defaultOptions = {
    reverse: false,  // reverse the tilt direction
    max: 35,     // max tilt rotation (degrees)
    perspective: 1000,   // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1,    // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000,   // Speed of the enter/exit transition
    transition: true,   // Set a transition on enter/exit.
    axis: null,   // What axis should be disabled. Can be X or Y.
    reset: true,    // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
  }
  return (
    <>
      <div className="hero-parent  parent" >

        <div className="overlay bg-img-cover"></div>
        <div className="overlay2"></div>
        <div className="hero-container container">
          <div className="hero-left" >
            <div className="partner"  data-aos="fade-right" data-aos-delay="200">
              <SectionIndicator title="your partner in health"   />
            </div>
            <h1 data-aos="fade-right" data-aos-delay="400">Holistic Care for Your Wellbeing</h1>
            <p data-aos="fade-right" data-aos-delay="600" className="hero-p">
              Experiance the power of personalised healthcare concierge serices that cater to your unique needs,ensuring exceptional care at every step.
            </p>

            <div className="cta-buttons" data-aos="fade-right" data-aos-delay="800">
              <Link className="services-btn btn-dark-blue" to="/contact">Contact us</Link>
            </div>
          </div>

          <div className="hero-right" data-aos="fade-up">
          {/* <Tilt options={defaultOptions} style={{ height: "fit-content", width: "fit-content" }}>
          <div className="hero-right-image"></div>
    </Tilt> */}
   <iframe src='https://my.spline.design/dnaparticles-553be4fcc60247993ad6510ce7cb6f7e/' frameborder='0' width='100%' height='100%'></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
