import React, { useState } from 'react'
import "../styles/clinical.scss"
import WhyChoose from "../components/WhyChoose.js"
import visit from "../photos/clinic-services/visit.webp"
import stress from "../photos/clinic-services/health-coach.webp"
import nutrition from "../photos/clinic-services/nutrition.webp"
import Faqs from '../components/Faqs.js'
import Headertop from '../components/Headertop.js'
import { Helmet } from 'react-helmet'


function ClinicalServices() {
  const data1 = [
    {
        title: "Why leave the comfort of your home to consult a doctor?",
        list: [" Healthcare Concierge provides expert doctors, physicians, and specialists for consultation at the convenience of your home.", "Undergo physical health checkups.",      "Critical and elderly care is available with ICU-trained nurses and medical attendants."," Cost-effective healthcare services at home."
      ]
    },
    {
      title: "Advantages of a doctor's visit",
      list: ["Reduced risk of hospital-acquired infection.","The mental wellbeing of the patient and caregivers .","Round-the-clock treatment and monitoring.","Recover faster with your loved ones around."," Doctors administer medicines that speed up recovery.","Personalised care to suit your every need."
    ]
  },
    
  ]
  const [faqstate,setfaqstate] = useState(3)
const faq = [
    {
      title: "What is a clinical service?",
      content: "A clinical service refers to a range of healthcare services provided by medical professionals, often in a clinical or outpatient setting, to diagnose, treat, and manage various medical conditions."
    },
    {
      title: "What is a concierge medical care service?",
      content: "Concierge medical care is a personalised healthcare service where patients pay a membership fee to receive enhanced access to a primary care physician, often including same-day appointments, longer appointment times, and direct communication with the doctor."
    },
    {
      title: "How does concierge medicine differ from traditional healthcare?",
      content: "Concierge medicine offers a more personalised and convenient healthcare experience compared to traditional healthcare. Patients in concierge medicine practices often have more time with their doctors and quicker access to medical services"
    },
    {
      title: "What services are typically offered in a clinical healthcare setting?",
      content: "Clinical health care services encompass a wide range of medical services, including preventive care, diagnostic tests, treatment for acute and chronic illnesses, vaccinations, and specialist referrals."
    },
    {
      title: "Are there clinics that specialise in concierge medical services?",
      content: "Yes, some clinics specialise in concierge medical services, offering patients a higher level of personalised care and convenience."
    },
    {
      title: "Can I request a doctor's home visit through concierge medical care?",
      content: "Yes, many concierge medical services offer doctors' home visits for patients who require medical attention in the comfort of their own homes."
    },
    {
      title: "How can I find a concierge medicine doctor in my area?",
      content: "You can search online, ask for referrals from friends or family, or contact your local medical association for a list of concierge medicine practitioners in your area."
    },
    {
      title: "What are the benefits of choosing concierge medical care?",
      content: "Benefits of concierge medical care include more personalised attention from your doctor, shorter wait times, extended appointment durations, and convenient access to healthcare services."
    },
    {
      title: "Are concierge medical services covered by insurance?",
      content: "Typically, concierge medical services are not covered by insurance. Patients pay a membership fee to access these services, in addition to their regular health insurance."
    },
    {
      title: "Can I still use my health insurance with a concierge medicine doctor?",
      content: "Yes, you can use your health insurance for services that are covered by your policy, such as specialist referrals or hospital stays. Concierge medicine often complements existing insurance coverage."
    },
    {
      title: "How does a concierge medicine doctor differ from a doctor in a traditional clinic?",
      content: "Concierge medicine doctors usually have a smaller patient load, allowing them to provide more personalised care and build stronger doctor-patient relationships compared to doctors in traditional clinics."
    },
    {
      title: "Is concierge medicine only for the wealthy?",
      content: "While concierge medicine may have a membership fee, it's becoming more accessible to a wider range of patients, and some practices offer different pricing tiers to accommodate various budgets."
    },
    {
      title: "Do concierge medicine doctors offer after-hours care?",
      content: "Many concierge medicine doctors provide after-hours care or have arrangements for patients to reach them in case of emergencies."
    },
    {
      title: "How do I schedule an appointment with a concierge medicine doctor?",
      content: "You can typically schedule appointments with a concierge medicine doctor by calling their office, sending an email, or using a dedicated patient portal."
    },
    {
      title: "Can I switch back to traditional healthcare if I try concierge medicine and it's not for me?",
      content: "Yes, you can always transition back to traditional healthcare if you find that concierge medicine doesn't meet your needs or preferences."
    },
  ]
  return (
    <>
    <Helmet>
      <title>Clinical Services - Healthcare Concierge</title>
      <meta name="description" content="Experience comprehensive and personalized healthcare, catered to your specific needs and well-being."  />
      <meta name="keywords" content="healthcare services, healthcare uk, medical concierge, healthcare it services, healthcare app, health service, medical medical insurance, health help, health care system in the uk, cares health" />
      <link rel="canonical" href="https://healthcareconcierge.net/clinical-services" />
    </Helmet>
    <Headertop title="Clinical Services" desc="24/7 Personalised Healthcare Provider"/>
    <div className="accorliwhy-parent parent">
        <div className="accorli-whychooseus-container" >
                <WhyChoose
                img={visit}
                indicator="why choose us"
                title="Doctors Home Visits"
                content="Why inconvenience yourself when you can consult a doctor from the comfort of your own home? With Doctors Home Visits, Healthcare Concierge brings expert doctors, physicians, and specialists to you for consultations and health checkups.
                Our services extend to critical and elderly care, with ICU-trained nurses and medical attendants available. Enjoy cost-effective healthcare without sacrificing quality.
                The benefits of a doctor's visit at home are numerous: reduced risk of hospital-acquired infections, improved mental wellbeing for patients and caregivers, round-the-clock treatment and monitoring, faster recovery surrounded by loved ones, and personalised care tailored to your needs.
                Don't let healthcare disrupt your routine. Let Doctors Home Visits bring quality medical care directly to your doorstep.
                "
                accor={data1}
                 />
        </div>
    </div>

    <div className="strc-parent parent">
        <div className="c-whychooseus-container" >
                <WhyChoose
                img={stress}
                bg="var(--cream)"
                indicator="why choose us"
                title="Stress Management and Life/ Health Coaching"
                content="Stress impacts individuals uniquely, influenced by personality, background, and environment. Our comprehensive services and coaching assess your stress levels and their impact on your body and health. We prioritise actionable steps to foster positive life changes, aiming to mitigate or eliminate stressors."
                direction="row-reverse"
                 />
        </div>
    </div>
    <div className="nutritio-parent parent">
        <div className="nutrition-whychooseus-container" >
                <WhyChoose
                img={nutrition}
                bg="var(--cream)"
                indicator="why choose us"
                title="Nutrition and Fitness-Focused Wellness"
                content="We provide tailored fitness and nutrition programs, utilising metabolic and genetic data to craft a wellness regimen that aligns with your unique physiology. Our goal is to enhance your health and longevity through a comprehensive approach that transforms the core of your well-being. Each Elite visit includes a complimentary consultation with our nutrition and fitness specialists. Wellness is an ongoing journey of nurturing a healthy body and mind. Our Elite physicians prioritise wellness as the foundation of your health during every examination."
                 />
        </div>
    </div>

    <div className="faqs_parent1 parent">
        <div className="faqs_container1_home container">
          <h2>FAQ for Clinical Services</h2>
          {
            faq.slice(0,faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
          {
            faq.length >= faqstate &&  <button className='btn-dark-blue loadmore-btn' onClick={()=>setfaqstate(faqstate + 3)}>Load More</button>
          } 
          </center>
      
         
        </div>
      </div>
    

    

    </>
  
  )
}

export default ClinicalServices