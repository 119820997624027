import React from 'react';
import Headertop from '../components/Headertop';

function CookiePolicy() {
    return (
        <div className="parent">
            <Headertop title="Privacy Policy" />
            <div className="container" style={{ padding: "20px" }}>
                <p><strong>Cookies</strong></p>
                <p>We employ the use of cookies. By accessing HEALTHCARE CONCIERGE, you agreed to use cookies in agreement with the HEALTHCARE CONCIERGE's Privacy Policy.</p>

                <p>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                <br/>
                <h4>License</h4>
                <p>Unless otherwise stated, HEALTHCARE CONCIERGE and/or its licensors own the intellectual property rights for all material on HEALTHCARE CONCIERGE. All intellectual property rights are reserved. You may access this from HEALTHCARE CONCIERGE for your own personal use subjected to restrictions set in these terms and conditions.</p>

                <p>You must not:</p>
                <ul style={{ padding: "10px 30px", color: "rgba(110, 109, 109, 0.685)" }}>
                    <li>Republish material from HEALTHCARE CONCIERGE</li>
                    <li>Sell, rent or sub-license material from HEALTHCARE CONCIERGE</li>
                    <li>Reproduce, duplicate or copy material from HEALTHCARE CONCIERGE</li>
                    <li>Redistribute content from HEALTHCARE CONCIERGE</li>
                </ul>

                <p>This Agreement shall begin on the date hereof.</p>

                {/* Parts of this website... */}

                {/* HEALTHCARE CONCIERGE reserves the right... */}

                {/* You warrant and represent that:... */}

                {/* You hereby grant HEALTHCARE CONCIERGE... */}
                <br />
                <h4>Hyperlinking to our Content</h4>
                <p>The following organizations may link to our Website without prior written approval:</p>
                <ul style={{ padding: "10px 30px", color: "rgba(110, 109, 109, 0.685)" }}>
                    <li>Government agencies;</li>
                    <li>Search engines;</li>
                    <li>News organizations;</li>
                    <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                    <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                </ul>

                {/* You hereby grant HEALTHCARE CONCIERGE... */}

                {/* You warrant and represent that:... */}

                <p>These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>

                {/* We may consider and approve... */}

                {/* We will approve link requests... */}

                {/* These organizations may link... */}

                <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to HEALTHCARE CONCIERGE. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

                {/* Approved organizations may hyperlink... */}

                <p>No use of HEALTHCARE CONCIERGE's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
                <br/>
                <h4>iFrames</h4>
                <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
            </div>
        </div>

    );
}

export default CookiePolicy;
