import React, { useState } from 'react'
import Faqs from '../components/Faqs.js'
import "../styles/medical.scss";
import WhyChoose from '../components/WhyChoose';
import Card_section from '../components/Card_section';
import img from "../assets/hero.jpg";
import ppe from "../photos/medical-equipment/ppe.webp";
import gloves from "../photos/medical-equipment/gloves.webp";
import goggles from "../photos/medical-equipment/goggles.webp";
import mask from "../photos/medical-equipment/mask.webp";
import shield from "../photos/medical-equipment/shield.webp";
import sanitizer from "../photos/medical-equipment/sanitizer.webp";
import sterile from '../photos/medical-equipment/sterile.jpg';
import nonsterile from '../photos/medical-equipment/non-sterile.jpg'
import surgical from '../photos/medical-equipment/surgical.jpg';
import Headertop from '../components/Headertop.js';
import { Helmet } from 'react-helmet';





function Medical_equipment() {
  const list =[
    "Single use, individually packaged",
    "BS EN 13795:2019 compliant",
    "Sterile S,M,L,XL | Non-Sterile S,M,L",
  ]

  const list1 =[
    "Is Single use",
    "Latex free",
    "Instructions are in English",
    "Available in sizes S, M, L",
    "Used when there is a low risk of fluid",
  ]

  const list2 =[
    "Single use & Latex free",
    "Is anti-static",
    "Available in sizes S, M, L, XL as a minimum",
    "Individually packaged",
    
  ]

  const data1 =[
    {
      title:"Key Features",
      list: [
        
        "Single use & Latex free",
        "Most puncture resistant",
        "Have a high level of sensitivity",
        "Mould to your hand for a great fit",
        "Work well for high-risk situations involving infectious material",
        "Resist many chemicals",
        "Have a long shelf life",
        "Available in blue or black to help identify if the glove has been punctured",
    ]
    },
    
    
  ]

  const data2 =[
    {
      title:"Surgical Masks Type IIR (Disposable)",
      list: [
        
        "Single use",
        "Each mask is packaged individually",
        "Marked as Type IIR or equivalent",
        "Provides a bacterial filtration efficiency (BFE) of 98% or above",
        "Is close fitting in order to prevent venting (exhaled air ‘escaping’ at the sides of the mask)",
        "The nose band does not kink or break when adjusted",
        "The nose band deforms when pressed to mould over the nose and cheeks and must maintain its shape over time",
        "Has a splash resistance pressure equal to or greater than 120mm Hg",
        "Is free from chemical smells, resulting from the manufacturing process, which prevent the end user from breathing comfortably",
        " Has no residue left from the manufacturing process on the finished product which may lead to irritations on the skin",
    ]
    },
    {
      title:"Masks with Ties Have",
      list: [
        
        "Straps and ties do not detach from the face mask when in use",
        "Integral ties long enough to go around an adult head whilst wearing a surgical cap",
        " The upper tie sits at the crown of the head",
        "The lower tie is positioned to allow it to be positioned behind the neck to hold the sides of the mask against the face of the user to prevent any gaping",
        
    ]
    },
    {
      title:"Masks With Ear Loops Have",
      list: [
        
        "Elastic ear loops",
        "Ears loops do not detach from the face mask when in use",
        
        
    ]
    },
    {
      title:"Medical face mask(FFP2/N95 (Non Valved))",
      list: [
        
        "Single use, individually packaged",
        "Compliant to BS EN 149:2001+A1:2009",
        "Mask is marked as FFP2",
        
        
    ]
    },
    {
      title:"Medical face mask(FFP3/N99)",
      list: [
        
        "Single use, individually packaged",
        "Compliant to BS EN 14683:2019, BS EN 149:2001+AA1:2009",
        "Mask is marked as FFP3",
        
        
    ]
    },
    {
      title:"Filtering facepieces (FFP2) – Medical facemask (non-valved) details",
      list: [
        
        "Are single use & not valved",
        "Are moulded, duckbill, flat folded or cone style",
        "Straps/ties are adjustable for fit by the user",
        "Has integral straps/ties long enough to go around an adult head whilst wearing a surgical cap",
        "Upper strap/tie sits at the crown of the head",
        "The lower strap/tie is positioned to allow it to be positioned behind the neck to hold the sides of the mask against the face preventing any gaping",
        " The nose band must deform when pressed to mould over the nose and cheeks and must maintain its shape over time",
        "The nose band must not kink or break when adjusted",
        "The mask is marked as FFP2",




        
       ]
    },
    {
      title:"Filtering facepieces (FFP3) – Respiratory protective devices (not valved) details",
      list: [
        
        " Are single use & not valved",
        "Are moulded, duckbill, flat folded or cone style",
        "Straps/ties are adjustable for fit by the user",
        "Has integral straps/ties long enough to go around an adult head whilst wearing a surgical cap",
        "Upper strap/tie sits at the crown of the head",
        "The lower strap/tie is positioned to allow it to be positioned behind the neck to hold the sides of the mask against the face preventing any gaping",
        " The nose band must deform when pressed to mould over the nose and cheeks and must maintain its shape over time",
        "The nose band must not kink or break when adjusted",
        "The mask is marked as FFP3",
        




        
       ]
    },
    {
      title:"Ply disposable non-surgical masks",
      list: [
        
        " Single use",
        "Compliant to EN455 and Medical Devices Directive 93/42/EEC",
        " Protective three-layer protection",
        "The nose band does not kink or break when adjusted",
        "Upper strap/tie sits at the crown of the head",
        "The lower strap/tie is positioned to allow it to be positioned behind the neck to hold the sides of the mask against the face preventing any gaping",
        " The nose band must deform when pressed to mould over the nose and cheeks and must maintain its shape over time",
        "The nose band must not kink or break when adjusted",
        "Is free from chemical smells, resulting from the manufacturing process, which prevent the end user from breathing comfortably",
        




        
       ]
    },
    
    
  ]
  
  const data3 =[
    {
      title:"Hand Sanitiser 100ml / 500ml Details ",
      list: [
        
        " 75% Alcohol Hand Sanitiser Gel Antibacterial",
        " Disinfecting",
        "Quick Dry formula kills more than 99.99% of most common germs that may cause illnesses",
        
    ]
    },
    {
      title:"Direction for use",
      content:"Apply approximately 3ml of Hand Sanitiser and cover all surface of the hands without forgetting fingernails, thumbs between fingers and wrists. Rub hands until dry.",
    },
    
    
  ]
  const data4 =[
    {
      title:"Medical Goggles ",
      list: [
        
        " Worn on the head for covering the whole of the face and provides a barrier to liquid splashes",
        "Optically clear",
        "Resistant to fogging",
        "Have an adjustable headband",
        
    ]
    },
    {
      title:"Medical Goggles Details",
      list: [
        
        "Fully sealed medical goggles",
        "CE and FDA compliant",
        "Conforms to EN1731:2006",
        
        
    ]
    },
    {
      title:"Instructions ",
      list: [
        
        "Unpack the inner packaging, remove the goggles, and remove the protective film on both sides",
        " After the two ends of the foam strip (commonly known as elastic band) are fastened, wear it on the forehead to adjust in comfortably",
        
        
        
    ]
    },
    {
      title:"Storage Conditions",
      content:"Should be stored in a room with relative humidity not exceeding 80%, no corrosives, and well ventilated.",
    },
    {
    title:"Warnings",
    list: [
      
      "Do not expose the goggles to strong sunlight",
      "Handle carefully. The goggles must not touch sharp objects",
      "Do not use if the packaging is damaged or product is past its expiration date",
      
      
      
  ]
  },
    
    
  ]
  const data5 =[
    {
      title:"Visor / Face Shield",
      list: [
        
        "Worn on the head for covering the whole of the face and provides a barrier to liquid splashes",
        "Optically clear",
        "Resistant to fogging",
        "Have an adjustable headband"
        
    ]
    },
    {
      title:"Visor / Face Shield Details",
      list: [
        
        "Single use, individually packaged",
        "Compliant to BS EN 166:2001",
        "Adjustable headband",
        
        
    ]
    },
    {
      title:"Instructions",
      list: [
        
        "Unpack the inner packaging, remove the goggles, and remove the protective film on both sides",
        " After the two ends of the foam strip (commonly known as elastic band) are fastened, wear it on the forehead to adjust in comfortably",
        
        
        
        
    ]
    },
    {
      title:"Storage Conditions",
      content:"Should be stored in a room with relative humidity not exceeding 80%, no corrosives, and well ventilated.",
    },
    {
    title:"Warnings",
    list: [
      
      "Do not expose the goggles to strong sunlight",
      "Handle carefully. The goggles must not touch sharp objects",
      "Do not use if the packaging is damaged or product is past its expiration date",
      
      
      
  ]
  },
    
    
  ]
  const [faqstate,setfaqstate] = useState(3)
  const faq = [
      {
        title: "What are medical supplies?",
        content: "Medical supplies are essential items and products used in healthcare settings to diagnose, treat, and care for patients. They include items like bandages, syringes, gloves, and more."
      },
      {
        title: "What are healthcare supplies?",
        content: "Healthcare supplies are a broad category of products used in medical settings, including medical supplies, equipment, and consumables necessary for patient care."
      },
      {
        title: "What types of clinical equipment are commonly used in healthcare facilities?",
        content: "Clinical equipment includes devices such as blood pressure monitors, ECG machines, X-ray machines, and diagnostic tools used by healthcare professionals healthcare professionals to assess and monitor patient health."
      },
      {
        title: "Where can I find used healthcare equipment for sale?",
        content: "You can find used healthcare equipment for sale through medical supply companies, online marketplaces, auctions, and medical equipment dealers."
      },
      {
        title: "What are the advantages of purchasing used healthcare equipment?",
        content: "Buying used healthcare equipment can be cost-effective, allowing healthcare facilities to save money while still maintaining quality patient care."
      },
      {
        title: "Are there regulations for medical equipment and supplies?",
        content: "Yes, medical equipment and supplies are regulated by government agencies such as the FDA in the United States to ensure safety, effectiveness, and quality standards."
      },
      {
        title: "How can I find a concierge medicine doctor in my area?",
        content: "You can search online, ask for referrals from friends or family, or contact your local medical association for a list of concierge medicine practitioners in your area."
      },
      {
        title: "How can I source reliable healthcare equipment and supplies for my facility?",
        content: "To ensure reliability, purchase healthcare equipment and supplies from reputable suppliers and manufacturers known for quality products."
      },
      {
        title: "Can I order medical supplies and equipment online?",
        content: "Yes, many medical supply companies offer online ordering and delivery services for medical supplies and equipment."
      },
      {
        title: "What are some essential medical products commonly used in healthcare settings?",
        content: "Common medical products include surgical masks, gauze, wound dressings, catheters, and medical-grade cleaning and disinfecting supplies."
      },
      {
        title: "How can I ensure that the medical products I purchase meet industry standards?",
        content: "Look for medical products with relevant certifications and check if they comply with industry standards to ensure quality and safety."
      },
      {
        title: "Do healthcare facilities need regular maintenance for their medical equipment?",
        content: "Yes, routine maintenance and calibration of medical equipment are essential to ensure accuracy, reliability, and patient safety."
      },
      {
        title: "Can medical supply companies provide product services, such as maintenance and training?",
        content: "Many medical supply companies offer additional services like product training, maintenance, and technical support to assist healthcare facilities."
      },
      {
        title: "What are some considerations when purchasing medical equipment and supplies for a healthcare facility?",
        content: "Consider factors like budget, specific healthcare needs, product quality, supplier reputation, and regulatory compliance when making purchasing decisions"
      },
      {
        title: "How can I find healthcare equipment and supplies that match my facility's requirements?",
        content: "Consult with medical supply experts or equipment specialists who can help you identify the most suitable products for your healthcare facility."
      },
    ]



  return (
    <>
    <Helmet>
      <title>Medical Equipment Supply - Premium Healthcare in the UK</title>
      <meta name="description" content="Explore our comprehensive medical equipment supply at Healthcare Concierge. We provide essential Personal Protective Equipment (PPE)."  />
      <meta name="keywords" content="healthcare services, healthcare uk, medical concierge, uk healthcare, medical services, healthcare it services, medical app, health clinic, health service, services concierge, uk medicals, healthcare concierge, PPE" />
      <link rel="canonical" href="https://healthcareconcierge.net/medical-equipment" />
    </Helmet>
    <Headertop title="Medical Equipment" desc="State-of-Art Device Supply"/>
    <div className="medical-parent parent">
        <div className="medical-whychooseus-container" >

                <WhyChoose
                img={ppe}
                indicator="Medical Equipment"
                title="Personal protective equipment (PPE)"
                content="With over 20 years of experience in the medical field, Healthcare Concierge Consulting Limited has established a robust global supplier network. In response to the heightened demand during the COVID-19 pandemic, we have successfully sourced and verified CE and BS approved PPE. Our efficient delivery system ensures prompt distribution of PPE worldwide, with stocked inventory available in the UK for immediate delivery to customers in the UK and Europe. While the following are some of our commonly requested items, please feel free to contact us with your specifications and quantities needed, and we will leverage our network to find the best solutions for you."
                
                 />
        </div>
    </div>
    <div className="medicalcard-parent parent">
        <div className="medicalcard-whychooseus-container" >
        
                <Card_section 
                mainbg=""
                heading="Medical Gowns Come in Various Types​"
                img={surgical}
                img1={nonsterile}
                img2={sterile}
                bg="var(--cream)"
                height="550px"
                card1="Protective Surgical Gowns"
                card2="Non-Sterile Gown"
                card3="Sterile Gown"
                list={list}
                list1={list1}
                list2={list2}
                          />
                
        </div>
    </div>

    <div className="medicalse1-parent parent">
      <div className="medicalse1-container container">
      <WhyChoose indicator="why choose us"
      img={gloves}
                title="Medical Disposable Nitrile Gloves"
                content="Nitrile gloves, often referred to as medical grade, are made out of a synthetic rubber and are an ideal alternative when latex allergies are of concern. They are also a superior glove in terms of puncture resistance. Before gloves can be marketed to hospitals and medical institutions, they must undergo a series of tests conducted by the Food and Drug Administration (FDA) to ensure their durability."
                accor={data1} />
      </div>

    </div>
    <div className="medicalse2-parent parent">
      <div className="medicalse2-container container">
      <WhyChoose indicator="why choose us"
      img={mask}
                title="Surgical Masks/Medical Face Mask"
                content="Protecting healthcare staff and patients from infectious diseases. Medical face masks help prevent large particles expelled by the wearer (e.g. spit, mucous) from reaching the patient or work environment and help protect the wearer from exposure to blood and/or bodily fluids from the patient or work environment. Type IIR – EN14683 masks are appropriate for situations where exposure to blood and/or bodily fluids from the patient or work environment is at risk for the healthcare worker."
                direction="row-reverse"
                accor={data2} />
      </div>

    </div>

    <div className="medicalse3-parent parent">
      <div className="medicalse3-container container">
      <WhyChoose indicator="why choose us"
      img={sanitizer}
      
                title="Hand Sanitiser"
                content="Sanitiser contains 75% ethanol alcohol to help reduce the number of germs on hands, and four moisturisers to help keep skin hydrated. No rinsing with water or drying with towels is needed. Alcohol-based hand sanitisers can quickly reduce the number of germs on hands, but do not eliminate every kind of pathogenic micro-organism."
                direction=""
                accor={data3} />
      </div>

    </div>

    <div className="medicalse4-parent parent">
      <div className="medicalse4-container container">
      <WhyChoose indicator="why choose us"
      img={goggles}
                title="Medical Goggles"
                content="Medical goggles are worn on the head to cover the entire face, offering a protective barrier against liquid splashes. These goggles are optically clear, ensuring clear vision without distortion, and are resistant to fogging, maintaining visibility even in challenging conditions. Featuring an adjustable headband, they offer a comfortable and secure fit for various users. These fully sealed medical goggles meet CE and FDA standards, ensuring compliance with rigorous safety and quality regulations. Additionally, they conform to EN1731:2006 standards, further validating their reliability and suitability for medical use."
                direction="row-reverse"
                accor={data4} />
      </div>

    </div>
    <div className="medicalse5-parent parent">
      <div className="medicalse5-container container">
      <WhyChoose indicator="why choose us"
      img={shield}
                title="Visor / Face Shield"
                content="The Visor/Face Shield is worn on the head to cover the entire face, offering a protective barrier against liquid splashes. It boasts optical clarity and is resistant to fogging, ensuring clear visibility in various environments. Equipped with an adjustable headband, it ensures a comfortable and secure fit for the wearer. Additionally, it is designed for single-use and comes individually packaged, adhering to BS EN 166:2001 standards for safety and quality."
                direction=""
                accor={data5} />
      </div>

    </div>
    <div className="faqs_parent2 parent">
        <div className="faqs_container2_home container">
          <h2>FAQ for Medical Equipment</h2>
          {
            faq.slice(0,faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
          {
            faq.length >= faqstate &&  <button className='btn-dark-blue loadmore-btn' onClick={()=>setfaqstate(faqstate + 3)}>Load More</button>
          } 
          </center>
      
         
        </div>
      </div>
    
    
    
    
    
    
    
    
    </>
  )
}

export default Medical_equipment