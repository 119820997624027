import React from 'react'
import "../styles/blogcard.scss"
import { Link } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton';

const BlogCard = (props) => {
  const dateObject = new Date(props.date);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
  return (
    <Link to={`/blogs/${props.id}`} className="blogcard-parent">
      <div className="blogcard_left">
      {props.url ? (
          <div className="blogcard_left_img" style={{background: `url(${props.url})`}}></div>
        ) : (
          <Skeleton variant="rectangular" width={300} height={200} />
        )}
      </div>
      <div className="blogcard_right ">
        <div className="blog_card_top" >
          <span >{formattedDate}</span>
          <span>{props.cat}</span>
        </div>
        <div className="blog_card_title">
          <h1 className='blog-title'>{props.title.length >= 20 ? `${props.title.slice(0, 19) + "..."}` : props.title}</h1>
          <div className='blog_card_content' dangerouslySetInnerHTML={{ __html: props.content.length >= 20 ? `${props.content.slice(0, 90)}...` : props.content }} />
        </div>
      </div>
    </Link>
  )
}

export default BlogCard