import React from 'react'
import "../styles/sectiondivide.scss"
import { Link } from 'react-router-dom'
import BgVideo from "../videos/headertop.webm"
const SectionDividerCard = (props) => {
  return (
    <div className='section-divide-card-parent'>
      <video autoPlay loop muted className="bg-vid" style={{ position: "absolute", objectFit: "cover" ,zIndex:"1"}} width="100%" height="100%">
        <source src={BgVideo} type="video/mp4" />
      </video>
      <div className="vdoverlay"></div>
      <p className='divider-title'>{props.title}</p>
      <p className='divider-content'>{props.content}</p>
      <Link to={props.link} className='btn-dark-cream bk-btn'>Book Now</Link>
    </div>
  )
}

export default SectionDividerCard