import React from 'react'
import { Link } from 'react-router-dom';
import { MdCall } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";


import "../styles/footer.scss"
const Footer = () => {
  return (

    <>
      <div className='footer-parent parent'>
        <div className="top-footer-container container">

          <div className="column1 column">
            <h4 className='col-title'>Other Links</h4>

            <Link to="/">Home</Link>
            <Link to="/about">About Us</Link>
            <Link to="/contact">Contact Us</Link>
            <Link to="/blogs">Blogs</Link>
            <Link to="/strawberry-facelift">Strawberry Facelift</Link>
          </div>

          <div className="column2 column">
            <h4 className='col-title'>Our Services</h4>
            <Link to='/clinical-services'>Clinical Services</Link>
            <Link to='/healthcare-advocate'>Healthcare Advocate</Link>
            <Link to='/medical-equipment'>Medical Equipments</Link>

            <Link to='/healthcare-strategy'>Healthcare Strategy</Link>
            <Link to='/Medico-Legal'>Medico-Legal</Link>
            <Link to='/innovation&ai'>Innovation & AI</Link>
            <Link to='/loyalty-card'>Loyalty Card</Link>

          </div>

          <div className="column3 column">
            <h4 className='col-title'>Medical & Wellness Tourism
            </h4>
            <Link to='/executive-cheak-ups'>Executive Health Checkups</Link>
            <Link to='/dental-tourism'>Dental Tourism</Link>
            <Link to='/ivf-tourism'>IVF Tourism</Link>
            <Link to='/cosmatic-treatment'>Cosmetic Treatments</Link>
            <Link to='/electives-in-srilanka'>Elective In Sri Lanka</Link>
          </div>

          <div className="column4 column">

            <div className="icon-box">
              <span className='icon
              '><MdCall /></span>
              <span >  < a href="tel: +44 78348 99488" target="_blank">
                +44 78348 99488</a></span>
            </div>

            <div className="icon-box">

              <span className='icon'><IoIosMail /></span>
              <span>

                <a href="mailto:info@healthcareconcierge.net" target="_blank">
                  info@healthcareconcierge.net </a></span>
            </div>
            <div className="social-icon-box">
              <Link to='https://www.facebook.com/Health.Care.Concierge.London/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0' target='_blank' className='icon'><FaFacebookF /></Link>

              < Link to='https://www.instagram.com/healthcare.concierge/?igshid=MzRlODBiNWFlZA%3D%3D' target='_blank' className='icon'><FaInstagram /></Link>

              <Link to='https://www.linkedin.com/company/healthcare-concierge-london/' target='_blank' className='icon'><FaLinkedinIn /></Link>

            </div>
          </div>


        </div>

        <div className="bottom-footer-parent">
          <div className="bottom-footer-container container">
            <p className='copyright'>Copyright © 2024 <Link to="/"> Healthcare Concierge  </Link> | All rights reserved</p>


            <div className="policies">
              <Link to="/terms and conditions"> <p className='policy' >Terms & conditions</p></Link>
              <Link to="/privacy policy"> <p className='policy'>Privacy Policy</p></Link>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default Footer



// <Link to='#'>Privacy Policy</Link>
//             <Link to='#'>Terms & Conditions</Link>
//             <Link to='#'>Disclaimer</Link>
//             <Link to='#'>Cookie Policy</Link>