import React from 'react'
import WhyChoose from "../components/WhyChoose.js"
import '../styles/medicalwellness.scss';
import img1 from '../assets/dr1.jpg';
import Card_section from '../components/Card_section.js';
import healthchek from '../photos/health/healtha-checkup.webp';
import home from '../photos/health/home.webp';
import dental from '../photos/health/dental.webp';
import cosmatic from '../photos/health/cosmetic1.webp';
import ivf from '../photos/health/vip.webp'
import Headertop from '../components/Headertop.js';

function Medical_wellness() {

    return (
        <>
            <Headertop title="Medical Wellness & Tourism" desc="Coordinate travel and medical arrangements globally" />

            <div className="stresswhy-parent parent">
                <div className="stress-whychooseus-container" >
                    <WhyChoose
                        img={healthchek}
                        bg="var(--cream)"
                        indicator="why choose us"
                        title="Health Check-ups With healthcare Tourism"
                        content="Medical tourism is now more accessible, thanks to our Medical Tourism Solutions. We offer personalised medical care, facilitating quality healthcare while traveling abroad. Our team assists in locating qualified medical professionals in your preferred destination and coordinates all travel arrangements, from flights to accommodations. All medical professionals in our network are board-certified and highly experienced, guaranteeing top-notch care. With our assistance, you can access affordable healthcare options worldwide, ensuring your wellbeing is always prioritised."

                    />
                </div>
            </div>
            <div className="clinicalwhy-parent1 parent">
                <div className="clinical-whychooseus-container" >
                    <WhyChoose
                        img={home}
                        bg="var(--cream)"
                        indicator="why choose us"
                        title="Doctor Home Visits"
                        content="Wellness is an essential aspect of modern-day lifestyles.Prevention is better than cure forms the foundation of wellness tourism. At Healthcare Concierge and Consulting, we prioritise wellness and integrate best practices into your health check-ups. Medical and wellness tourism is a burgeoning trend among travelers, offering access to quality care and wellness experiences at a fraction of the cost, coupled with cultural immersion and exciting destinations. However, navigating this process independently can be daunting. That's where we step in! Our comprehensive medical tourism services streamline the entire journey, from identifying top doctors and facilities in your destination country to arranging appointments, accommodations, visas, insurance paperwork, transportation, and beyond. With us, you can confidently access quality care affordably and stress-free."
                        direction="row-reverse"

                    />
                </div>
            </div>

            <div className="card-bg1 parent ">

                <Card_section
                    mainbg="#FFF8E5"
                    heading="WHAT WE OFFER?"
                    // bg="var(--cream)"
                    height="auto"
                    btntext='Read More'
                    img={healthchek}
                    card1='Executive Health Check-ups'
                    card1para='Wellness is now the most important aspect of your daily lifestyle. "Prevention is better than cure," is the fundamental for wellness tourism. We at Healthcare Concierge and Consulting focus on wellness and introduce you to the best wellness practices during your health check-ups... '

                    img1={dental}
                    card2='Dental Tourism'
                    card2para='Dental tourism is growing worldwide; as the world becomes ever more interdependent and competitive, technique, material, and technological advances spread rapidly, allowing providers in developing countries to provide dental care at significant cost savings when compared to their peers in the developed world. A subset of medical tourism , dental tourism involves...'

                    img2={cosmatic}
                    card3='Cosmetic Treatment Tourism'
                    card3para='Cosmetic Treatment Tourism has become very popular in past few decades. Advance technology and cost effective treatments are major reason of growth in this segment. We can offer all-inclusive vacation packages that include cosmetic treatments which will be suitable for your budget in multiple countries. Cosmetic surgery procedures are not covered by insurance, price is... '

                    img3={ivf}
                    card4='IVF Tourism'
                    card4para='IVF tourism, or infertility tourism, has become a popular practice due to rising cases of infertility. The world is facing a rising trend of delayed pregnancy and infertility in females as well as males due to lifestyle issues. Advancements in assisted reproductive technologies coupled with cost savings and additional benefits to visitors such as improved healthcare, sophisticated devices,...'




                />

            </div>









        </>

    )
}



export default Medical_wellness