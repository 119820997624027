import React, { useState } from 'react'
import Faqs from '../components/Faqs.js'
import "../styles/medicoL.scss";
import WhyChoose from '../components/WhyChoose';
import Card_section from '../components/Card_section';
import img from "../assets/dr2.jpg";
// import legal from "../photos/legal/lega.webp"
import rights from '../photos/legal/rights.webp';
import vip from '../photos/legal/vip.jpg';
import legal from '../photos/legal/lega.webp';
import international from '../photos/legal/international.jpg';
import concerige from '../photos/legal/concerige.jpg';
import advocate from '../photos/legal/advocate.jpg';
import Headertop from '../components/Headertop.js';
import { Helmet } from 'react-helmet';

function MedicoL() {
  const [faqstate,setfaqstate] = useState(3)
  const faq = [
      {
        title: " What is healthcare concierge service?",
        content: "Healthcare concierge service is a personalised and premium healthcare service that offers patients enhanced access to medical care, assistance with appointments, and coordination of healthcare needs."
      },
      {
        title: "How can a healthcare concierge service benefit me?",
        content: "Healthcare concierge services can provide quicker access to medical care, help navigate the healthcare system, and offer a more personalised healthcare experience."
      },
      {
        title: "Is healthcare concierge legal in healthcare?",
        content: "Yes, healthcare concierge services are legal and operate within the bounds of healthcare regulations and laws."
      },
      {
        title: "How can I find in-home care assistance for myself or a loved one?",
        content: "You can find in-home care assistance by contacting home care agencies, consulting with healthcare professionals, or seeking recommendations from friends and family."
      },
      {
        title: "Are there legal issues in healthcare concierge services?",
        content: "While healthcare concierge services are legal, there can be legal considerations related to medical confidentiality, data privacy, and adherence to healthcare regulations."
      },
      {
        title: "What legal issues are commonly associated with healthcare concierge services?",
        content: "While healthcare concierge services are legal, there can be legal considerations related to medical confidentiality, data privacy, and adherence to healthcare regulations."
      },
      {
        title: "Are there specialised services like dementia care available?",
        content: "Yes, many home care agencies offer specialised services, including dementia care, to cater to the unique needs of individuals with specific conditions"
      },
      {
        title: "What legal issues are commonly associated with healthcare concierge services?",
        content: "Common legal issues in healthcare concierge services may include patient confidentiality, compliance with healthcare regulations, and billing practices."
      },
      {
        title: "Can healthcare concierge services assist with legal and medical matters?",
        content: "Healthcare concierge services can help coordinate medical services, but for legal matters, it's advisable to consult with legal professionals who specialise in healthcare law."
      },
      {
        title: "What is the role of medical legal services in healthcare?",
        content: "Medical legal services provide legal expertise in healthcare-related matters, such as malpractice claims, healthcare compliance, and medical ethics."
      },
      {
        title: "Are there specific legal considerations when handling medical cases?",
        content: "Yes, legal considerations in medical cases involve adherence to medical ethics, patient consent, confidentiality, and ensuring that medical professionals follow established legal standards."
      },
      {
        title: "How can healthcare organisations ensure compliance with healthcare laws and regulations?",
        content: "Healthcare organisations can ensure compliance by regularly reviewing and updating their policies, training staff on legal requirements, and seeking legal counsel when necessary."
      },
      {
        title: "What should I do if I have legal concerns related to my healthcare?",
        content: "If you have legal concerns related to your healthcare, consult with an attorney who specialises in healthcare law to address your specific issues."
      },
      {
        title: "Can healthcare concierge services provide legal guidance?",
        content: "Healthcare concierge services primarily focus on coordinating medical care and services, so for legal guidance, it's best to consult with legal professionals."
      },
     
      {
        title: "How do healthcare professionals stay informed about legal matters in healthcare?",
        content: "Healthcare professionals stay informed about legal matters by attending continuing education programs, consulting with legal experts, and staying updated on changes in healthcare laws and regulations."
      },
      {
        title: "Can healthcare legal issues affect patient care?",
        content: "Legal issues in healthcare can potentially impact patient care, particularly if they lead to delays or disruptions in the delivery of medical services."
      },
      {
        title: "How can patients protect their legal rights in healthcare?",
        content: "Patients can protect their legal rights by being informed, maintaining records of their medical care, and seeking legal counsel when necessary to address healthcare-related concerns."
      },
    ]
  const data1 =[
    {
      title:"Examples of Medico-Legal Cases include",
      list: [
        
        "Injuries resulting from accidents, whether industrial, vehicular, or otherwise.",
        "Injuries with a potential risk of fatality.",
        "Injuries inflicted by firearms.",
        "Suspected or evident cases of suicide or homicide, including attempted incidents.",
    ]
    },
    
    
  ]
  return (
    <>
     <Helmet>
      <title>Medico Legal - Premium Healthcare in the UK</title>
      <meta name="description" content="Your trusted destination for medico-legal cases. From accidents to legal guidance, get VIP healthcare treatment and expert support."  />
      <meta name="keywords" content="healthcare services, medico-legal case, accidents, whether industrial, vehicular, firearms, cases of suicide or homicide, healthcare concierge, personal healthcare, vip treatement, legal guidance " />
      <link rel="canonical" href="https://healthcareconcierge.net/Medico-Legal" />
    </Helmet>
    <Headertop title="Medico-Legal" desc="Provide Guidance on Medical Legalese"/>
      <div className="medico1-parent parent">
        <div className="medico1-container container">
          <WhyChoose indicator="why choose us"
          img={legal}
            title="What is a Medico - Legal case"
            content="A medico-legal case refers to a medical scenario that carries legal implications. Typically, a medical case transforms into a medico-legal case when the attending physician assesses the patient's condition and history and concludes that law enforcement intervention is necessary. Additionally, medical examinations conducted for patients brought in by law enforcement or the court also fall under this category."
            accor={data1} />
        </div>
      </div>
      <div className="medicocard-parent parent">
        <div className="medicocard-whychooseus-container" >
                <Card_section 
                mainbg=""
                bg="var(--cream)"
                heading="WHAT WE OFFER?​"
                img={rights}
                img1={concerige}
                img2={advocate}
                img3={vip}
                img4={international}
                height="auto"
                card2="Healthcare Concierge"
                card2para="Healthcare simply isn’t what it used to be! Most primary care physicians will see an average of 30 to 50 individuals a day, with scarcely more than 15 minutes to devote to each patient at the detriment of conversation and history taking which comprise the art of diagnosis. When quantity supersedes quality, medical care inherently suffers."
                card3="Personal Healthcare Advocate"
                card3para="You will be provided with a Healthcare Advocate who will manage all aspects of your plan.Your advocate will take control of scheduling, finding specialists, and coordinating insurance information with other providers. In addition, you will have assistance finding the best doctors and healthcare institutions if you are out of town."
                card4="VIP Treatment"
                card4para="Limited wait time, customised care, priority physician appointments and diagnostic scheduling – what more can you ask for? We welcome you to experience the finest care that Concierge Medicine has to offer. Our luxurious red carpet treatment is just the beginning."
                card5="International Care"
                card5para="Healthcare Concierge and Consulting, members who are traveling or are away from their primary care physician for an extended period of time receive concierge-style healthcare services from the in-network physician team. Members have immediate access to qualified medical care from the comfort and privacy of their hotel room, temporary residency, or home."
                
                          />
                
        </div>
    </div>
    <div className="faqs_parent5 parent">
        <div className="faqs_container5_home container">
          <h2>FAQ for Medico Legal</h2>
          {
            faq.slice(0,faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
          {
            faq.length >= faqstate &&  <button className='btn-dark-blue loadmore-btn' onClick={()=>setfaqstate(faqstate + 3)}>Load More</button>
          } 
          </center>
      
         
        </div>
      </div>

















    </>
  )
}

export default MedicoL