import React, { useEffect, useState } from 'react'
import "../styles/aboutdivider.scss"
import SectionIndicator from './SectionIndicator'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { MdOutlineReviews } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { IoIosPeople } from "react-icons/io";
const AboutDivider = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView])
    return (
        <div className="about-divider">
            <div className="about-divider-left" >
                {/* <SectionIndicator title="about us"/> */}
                <div className="about-divider-heading title">
                    Advanced Diagnostic Center
                </div>
                <div className="about-divider-content content" ref={ref}>
                    Our diagnostic center has state-of-the-art MRI, CT, X-ray, and ultrasound imaging within our private hospital that pivots on discreet care—a complete healthcare delivery solution.
                </div>
                {isVisible && <div className="about-divider-counter">
                    <div className="counter-box">
                        <span className='counter-number'><CountUp end={8000} duration={5} />+</span>
                        {/* <span className='counter-text'>Reviews</span> */}
                        <span className='counter-icon'><TbReportSearch /></span>
                    </div>
                    <div className="counter-box">
                        <span className='counter-number'><CountUp end={20} duration={5} />+</span>
                        {/* <span className='counter-text'>Linkedin Connections</span> */}
                        <span className='counter-icon linkedin'><IoIosPeople /></span>
                    </div>
                </div>
                }

                <Link className='btn-dark-cream ' to="/contact">Contact Us</Link>
            </div>
            <div className="about-divider-right" >
                <div className="about-divider-right-image" style={{ background: `url(${props.img})` }}>

                </div>
            </div>
        </div>
    )
}

export default AboutDivider