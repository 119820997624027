import React, { useState } from 'react'
import Faqs from '../components/Faqs.js'
import "../styles/ivf.scss";
import WhyChoose from '../components/WhyChoose';
import cosmatic from '../photos/health/cosmatic.webp'
import Headertop from '../components/Headertop.js';

function Cosmatic_tourism() {
    const [faqstate,setfaqstate] = useState(3)
    const faq = [
        {
          title: "What is beauty enhancement?",
          content: "Beauty enhancement refers to various procedures and treatments aimed at improving one's appearance, often focusing on skin, hair, and body features."
        },
        {
          title: "Why do people seek beauty enhancement procedures?",
          content: "People seek beauty enhancement procedures to boost their self-esteem, address specific cosmetic concerns, and achieve their desired aesthetic goals."
        },
        {
          title: "What is a cosmetic expert?",
          content: "A cosmetic expert is a qualified professional with expertise in cosmetic procedures, treatments, and surgeries designed to enhance a person's appearance."
        },
        {
          title: "What are cosmetic beauty treatments?",
          content: "Cosmetic beauty treatments encompass various non-surgical procedures and therapies to enhance facial or body features, including facials, chemical peels, and injectables like Botox."
        },
        {
          title: "What is a cosmetic treatment?",
          content: "Cosmetic treatment includes surgical and non-surgical options and any procedure or therapy undertaken to enhance one's physical appearance."
        },
        {
          title: "What is enhanced cosmetic surgery?",
          content: "Enhance cosmetic surgery typically refers to surgical procedures performed to improve or enhance specific aspects of a person's appearance, such as breast augmentation, rhinoplasty, or liposuction."
        },
        {
          title: "What are the effects of cosmetic surgery?",
          content: "The effects of cosmetic surgery can vary depending on the procedure but often result in improved aesthetics, increased self-confidence, and enhanced overall appearance."
        },
        {
          title: "What are some common beauty enhancement treatments?",
          content: "Common beauty enhancement treatments include facials, chemical peels, microdermabrasion, dermal fillers, Botox injections, and laser skin rejuvenation."
        },
        {
          title: "Are cosmetic procedures safe?",
          content: "Cosmetic procedures are generally safe when performed by qualified, experienced professionals in accredited facilities. However, like any medical procedure, there are inherent risks, and individuals should discuss these with their providers."
        },
        {
          title: "How can I find a reputable cosmetic expert or clinic?",
          content: "To find a reputable cosmetic expert or clinic, research their qualifications, reviews , and certifications, and consider consulting with multiple professionals before deciding."
        },
        {
          title: "Are there alternatives to IVF for fertility treatment?",
          content: "Yes, alternatives to IVF include intrauterine insemination (IUI), fertility medications, surgical interventions, and lifestyle modifications, depending on the underlying cause of infertility."
        },
        {
          title: "What is the recovery process like after cosmetic surgery?",
          content: "The recovery process after cosmetic surgery varies depending on the procedure. It may involve rest, wound care, and post-operative appointments with the surgeon to monitor healing."
        },
        {
          title: "Can cosmetic procedures be customised to individual needs?",
          content: "Many cosmetic procedures can be tailored to meet individual needs and preferences, allowing for a personalised approach to beauty enhancement."
        },
        {
          title: "Are there age restrictions for cosmetic procedures?",
          content: "Age restrictions for cosmetic procedures may vary depending on the treatment and the patient's health. Consult with a cosmetic expert to determine eligibility."
        },
       
        {
          title: "How can I prepare for a cosmetic procedure?",
          content: "Preparing for a cosmetic procedure typically involves discussing your goals and expectations with your provider, following pre-operative instructions, and arranging post-operative care and support."
        },
        {
          title: "Can cosmetic procedures be combined for a more comprehensive makeover?",
          content: "Some individuals opt for multiple cosmetic procedures performed simultaneously or in stages to achieve a more comprehensive transformation."
  
        },
        
        
      ]
    return (
        <>
        <Headertop title="Cosmetic Treatment" desc="World-class cosmetic treatments"/>
            <div className="stresswhy-parent parent">
                <div className="stress-whychooseus-container" >
                    <WhyChoose
                        img={cosmatic}
                        bg="var(--cream)"
                        indicator="why choose us"
                        title="Cosmetic Treatment Tourism"
                        content="Cosmetic Treatment Tourism has surged in popularity over the past few decades, driven by advanced technology and cost-effective procedures. We offer all-inclusive vacation packages tailored to your budget, featuring cosmetic treatments in multiple countries. Since cosmetic surgery procedures are typically not covered by insurance, the cost is a significant factor. Our packages provide a cost-effective solution, bundling vacation and treatment expenses for a comprehensive and affordable experience."

                    />
                </div>
            </div>
            <div className="faqs_parent7 parent">
        <div className="faqs_container7_home container">
          <h2>FAQ for Cosmetic Treatments</h2>
          {
            faq.slice(0,faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
          {
            faq.length >= faqstate &&  <button className='btn-dark-blue loadmore-btn' onClick={()=>setfaqstate(faqstate + 3)}>Load More</button>
          } 
          </center>
      
         
        </div>
      </div>
        </>
    )
}

export default Cosmatic_tourism