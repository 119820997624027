import React from 'react';
import Headertop from '../components/Headertop';

function TermsAndConditions() {
    return (
        <div className="parent">
            <Headertop title="Term & Conditions" />
            <div className="container" style={{ padding: "20px" }}>
                <p>Welcome to HEALTHCARE CONCIERGE!</p>
                <p>These terms and conditions outline the rules and regulations for the use of HEALTHCARE CONCIERGE's Website, located at www.healthcareconcierge.net</p>

                <h4>1. Acceptance of Terms</h4>
                <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use HEALTHCARE CONCIERGE if you do not agree to take all of the terms and conditions stated on this page.</p>
                <br />
                <h4>2. Terminology</h4>
                <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer:</p>
                <ul style={{ padding: "10px 30px", color: "rgba(110, 109, 109, 0.685)" }}>
                    <li>"Client", "You" and "Your" refers to you, the person who logs on to this website and complies with the Company's terms and conditions.</li>
                    <li>"The Company", "Ourselves", "We", "Our" and "Us" refer to our Company.</li>
                    <li>"Party", "Parties", or "Us" refers to both the Client and ourselves.</li>
                    <li>All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of the provision of the Company's stated services, in accordance with and subject to prevailing law of Netherlands.</li>
                </ul>

                {/* Add more sections as needed */}
                <br />
                <h4>3. License</h4>
                <p>Unless otherwise stated, HEALTHCARE CONCIERGE and/or its licensors own the intellectual property rights for all material on HEALTHCARE CONCIERGE. All intellectual property rights are reserved. You may access this from HEALTHCARE CONCIERGE for your own personal use subjected to restrictions set in these terms and conditions.</p>
                <p>You must not:</p>
                <ul style={{ padding: "10px 30px", color: "rgba(110, 109, 109, 0.685)" }}>
                    <li>Republish material from HEALTHCARE CONCIERGE</li>
                    <li>Sell, rent, or sub-license material from HEALTHCARE CONCIERGE</li>
                    <li>Reproduce, duplicate, or copy material from HEALTHCARE CONCIERGE</li>
                    <li>Redistribute content from HEALTHCARE CONCIERGE</li>
                </ul>
                <br />
                <h4>4. Agreement Commencement</h4>
                <p>This Agreement shall begin on the date hereof.</p>
                <br />
                <h4>5. Comments Policy</h4>
                <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. HEALTHCARE CONCIERGE does not filter, edit, publish, or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of HEALTHCARE CONCIERGE, its agents, and/or affiliates.</p>
                <p>HEALTHCARE CONCIERGE reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.</p>
                <br />
                <h4>6. Comments Warranty</h4>
                <p>You warrant and represent that:</p>
                <ul style={{ padding: "10px 30px", color: "rgba(110, 109, 109, 0.685)" }}>
                    <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so.</li>
                    <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party.</li>
                    <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy.</li>
                    <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                </ul>
                <br />
                <h4>7. License Grant</h4>
                <p>You hereby grant HEALTHCARE CONCIERGE a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.</p>
            </div>
        </div>
    );
}

export default TermsAndConditions;
