import React from 'react'
import "../styles/testimonial.scss"
import SectionIndicator from "./SectionIndicator"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
const Testimonial = () => {
  return (
    <div className="testimonial-wrapper parent">
      <div className="testimonial-container container">
      <SectionIndicator title="Our Testimonials" />
      <div className="testimonial-heading">
        What People Say About Us
      </div>


      <div className="carousel-box">

        <Swiper pagination={true} 
        modules={[Pagination]}
      //  slidePerview={2}
      //  spaceBetween={40}
         className="mySwiper swiper-parent">

          <SwiperSlide className='swiper-slides'>
            <div className='slide-text'>Best private medical service there is, in London. Friendly, attentive, professional staff. Doctors are on call and available 24/7.This has helped me multiple times getting sick late at night. Highly recommended.</div>
            <div className="slide-author">
              Celine            </div>
          </SwiperSlide>

          <SwiperSlide className='swiper-slides'>
            <div className='slide-text'>Honestly, offering the best service. Always on time and provide the best quality of health care.They are true life savers.</div>
            <div className="slide-author">
              Sara
            </div>
          </SwiperSlide>

  
        </Swiper>
      </div>
      </div>
    </div>

  )
}

export default Testimonial