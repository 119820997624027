import React from 'react'
import "./popup.scss"
import { FaWhatsapp } from "react-icons/fa"
import { IoCallOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
const PopupButton = () => {
    const phoneNumber = '+447834899488'; // Replace with the recipient's phone number
    const message = "Hello! I'm interested in scheduling a medical appointment. Can you please provide me with available dates?"; // Replace with the message you want to send

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
    };
    return (
        <div className="popup-btn-box">
            <div className="whatsapp">
                <a href="#" className="icons-boxs" onClick={handleWhatsAppClick}>
                    <span className='w-icon'><FaWhatsapp /></span>
                </a>

                <a to="/contact" className="icons-boxs" href='tel:+447834899488'>
                    <span className='c-icon'><IoCallOutline /></span>
                </a>


            </div>
        </div>
    )
}

export default PopupButton