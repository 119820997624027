import React from 'react'
import "../styles/sectionindicator.scss"
const SectionIndicator = (props) => {
    return (
        <div className='section-indicator-parent'>
            <div className="line"></div>
            <div className="section-name">
                {props.title}
            </div>
        </div>
    )
}

export default SectionIndicator