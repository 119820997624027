import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import "../styles/ourserviceswiper.scss"
import { FiArrowRight } from "react-icons/fi";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import clise from "../photos/clinic-services/visit.webp"
import medeq from "../photos/medical-equipment/ppe.webp"
import hcoch from "../photos/healthcare/health-coach.webp"
import adv from "../photos/advocate/advoacte.webp"
import medleg from "../photos/legal/lega.webp"
import lcard from "../photos/loyality/personal.webp"
import ai1 from "../photos/ai/ai1.webp"
const OurServicesCarousel = () => {
    const screenWidth = window.innerWidth;
    console.log(screenWidth)
    return (
        <div className='ourservice-container container'>
            <h2 className='ser-text'>Our Services</h2>

            {
                screenWidth < 800 ?  <Swiper
                style={{
                  '--swiper-navigation-color': '#fff',
                  '--swiper-pagination-color': '#fff',
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                lazy={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation , Autoplay]}
                className="ourservice-swiper"
              >
                <SwiperSlide className='swiper-slide-mobile'>
                  <div className="swiper-div">
                  <div className="bg-img-cover service-swiper-img" style={{ background: `url(${clise})` }}></div>
                    <h3 className="service-swiper-text">Clinical Services</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/clinical services">Visit</Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-mobile'>
                  <div className="swiper-div">
                  <div className="bg-img-cover service-swiper-img" style={{ background: `url(${medeq})` }}></div>
                    <h3 className="service-swiper-text">Medical Equipment</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/medical equipment">Visit</Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-mobile'>
                  <div className="swiper-div">
                  <div className="bg-img-cover service-swiper-img" style={{ background: `url(${hcoch})` }}></div>
                    <h3 className="service-swiper-text">Healthcare Strategy</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/healthcare strategy">Visit</Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-mobile'>
                  <div className="swiper-div">
                  <div className="bg-img-cover service-swiper-img" style={{ background: `url(${adv})` }}></div>
                    <h3 className="service-swiper-text">Healthcare Advocate</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/healthcare advocate">Visit</Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-mobile'>
                  <div className="swiper-div">
                  <div className="bg-img-cover service-swiper-img" style={{ background: `url(${medleg})` }}></div>
                    <h3 className="service-swiper-text">Medico-Legal</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/Medico-Legal">Visit</Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-mobile'>
                  <div className="swiper-div">
                  <div className="bg-img-cover service-swiper-img" style={{ background: `url(${ai1})` }}></div>
                    <h3 className="service-swiper-text">Innovation & AI</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/innovation & ai">Visit</Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-mobile'>
                  <div className="swiper-div">
                  <div className="bg-img-cover service-swiper-img" style={{ background: `url(${lcard})` }}></div>
                    <h3 className="service-swiper-text">Loyalty Card</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/loyalty card">Visit</Link>
                  </div>
                </SwiperSlide>
              
              </Swiper>
              
              : <Swiper
                slidesPerView={5}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}

                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                modules={[Pagination, Autoplay]}
                className="ourservice-swiper"
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    },
                    400: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                    500: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    650: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    1400: {
                        slidesPerView: 5,
                        spaceBetween: 40,
                    },
                }}


            >
                <SwiperSlide className='serviceswiper-slide'>
                    <div className="bg-img-cover service-swiper-img" style={{ background: `url(${clise})` }}></div>
                    <h3 className="service-swiper-text">Clinical Services</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/clinical services">Visit</Link>
                </SwiperSlide>
                <SwiperSlide className='serviceswiper-slide'>
                    <div className="bg-img-cover service-swiper-img" style={{ background: `url(${medeq})` }}></div>
                    <h3 className="service-swiper-text">Medical Equipment</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/medical equipment">Visit</Link>
                </SwiperSlide>
                <SwiperSlide className='serviceswiper-slide'>
                    <div className="bg-img-cover service-swiper-img" style={{ background: `url(${hcoch})` }}></div>
                    <h3 className="service-swiper-text">Healthcare Strategy</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/healthcare strategy">Visit</Link>
                </SwiperSlide>
                <SwiperSlide className='serviceswiper-slide'>
                    <div className="bg-img-cover service-swiper-img" style={{ background: `url(${adv})` }}></div>
                    <h3 className="service-swiper-text">Healthcare Advocate</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/healthcare advocate">Visit</Link>
                </SwiperSlide>
                <SwiperSlide className='serviceswiper-slide'>
                    <div className="bg-img-cover service-swiper-img" style={{ background: `url(${medleg})` }}></div>
                    <h3 className="service-swiper-text">Medico-Legal</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/Medico-Legal">Visit</Link>
                </SwiperSlide>
                <SwiperSlide className='serviceswiper-slide'>
                    <div className="bg-img-cover service-swiper-img" style={{ background: `url(${ai1})` }}></div>
                    <h3 className="service-swiper-text">Innovation & AI</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/innovation & ai">Visit</Link>
                </SwiperSlide>
                <SwiperSlide className='serviceswiper-slide'>
                    <div className="bg-img-cover service-swiper-img" style={{ background: `url(${lcard})` }}></div>
                    <h3 className="service-swiper-text">Loyalty Card</h3>
                    <Link className='swiper-service-btn btn-dark-blue' to="/loyalty card">Visit</Link>
                </SwiperSlide>
            </Swiper>
            }
            
        </div>
    )
}

export default OurServicesCarousel