import React, { useEffect, useState } from 'react'
import "../styles/blogdetail.scss"
import Headertop from '../components/Headertop'
import { useParams } from 'react-router-dom'
import axios from 'axios'
const Blogdetail = () => {
  const [data, setdata] = useState([])
  const { id } = useParams()
  console.log(id)
  const fetchData = () => {
    axios.get(`https://www.api.healthcareconcierge.net/api/blogs/${id}/`)
      .then(res => setdata(res.data))
      .catch(err => console.log(err))
  }
  useEffect(() => {
    fetchData();
  }, [])
  console.log(data)
  const dateObject = new Date(data.date);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
  return (
    <>
      <Headertop title="Our Blogs" />
      <div className="blog_detail_parent parent">
        <div className="blog_detail_container container">
          <div className="blog_detail_top" style={{background:`url(${data.image})`}}>
          </div>
          <div className="blog_detail_bottom">
            <div className="bottom_details">
              <div className="date btn-dark-cream">{formattedDate}</div>
              <div className="date btn-dark-cream">{data.category && data.category.name}</div>
            </div>
            <div className="blog_detail_heading">
              <h1>{data.title}</h1>
            </div>
            <div
              className="blog_detail_content"
              dangerouslySetInnerHTML={{ __html: data.blog_content }}
            ></div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Blogdetail