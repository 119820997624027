import React, { useState } from 'react'
import "../styles/ivf.scss";
import Faqs from '../components/Faqs.js'
import WhyChoose from '../components/WhyChoose';
import bg from "../assets/hero.jpg"
import Headertop from '../components/Headertop.js';

function Ivf_tourism() {
    const [faqstate,setfaqstate] = useState(3)
    const faq = [
        {
          title: "What is IVF (In Vitro Fertilization)?",
          content: "In Vitro Fertilization (IVF) is a medical procedure where an egg and sperm are combined outside the body in a laboratory dish to create an embryo, which is then transferred into the uterus to establish a pregnancy."
        },
        {
          title: "Who is a candidate for IVF?",
          content: "IVF is typically considered for couples or individuals facing fertility issues, including those with blocked fallopian tubes, low sperm count, ovulation disorders, or unexplained infertility."
        },
        {
          title: "Where can I find a dental care clinic?",
          content: "You can find dental care clinics in your area by searching online, asking for recommendations from friends and family, or contacting your local dental association."
        },
        {
          title: "What are fertility treatment medications?",
          content: "Fertility treatment medications are prescribed to enhance fertility by stimulating egg production or regulating hormonal imbalances in individuals undergoing fertility treatments."
        },
        {
          title: "Are fertility medications and IVF treatments the same?",
          content: "No, fertility medications are often used with various fertility treatments, including IVF, to optimise the chances of conception."
        },
        {
          title: "Do dental care clinics accept dental insurance?",
          content: "Many dental care clinics accept dental insurance, but you should check with the clinic and your insurance provider to confirm coverage and network providers."
        },
        {
          title: "How does IVF work?",
          content: "IVF involves several steps, including ovarian stimulation, egg retrieval, fertilisation in the laboratory, embryo culture, and embryo transfer into the uterus. The specific process may vary based on individual needs."
        },
        {
          title: "What are common fertility medications used in IVF?",
          content: "Common fertility medications used in IVF include gonadotropins, Clomiphene citrate, Letrozole, and Human Chorionic Gonadotropin (hCG)."
        },
        {
          title: "Are fertility medications safe?",
          content: "Fertility medications are generally safe when prescribed and monitored by a healthcare provider. However, they may have side effects, and risks should be discussed with a medical professional."
        },
        {
          title: "How successful is IVF in the US?",
          content: "IVF success rates in the US vary depending on age, underlying fertility issues, and the specific clinic's protocols. Success rates are typically reported as percentages per cycle."
        },
        {
          title: "Are there alternatives to IVF for fertility treatment?",
          content: "Yes, alternatives to IVF include intrauterine insemination (IUI), fertility medications, surgical interventions, and lifestyle modifications, depending on the underlying cause of infertility."
        },
        {
          title: "What should I expect during IVF treatment?",
          content: "During IVF treatment, you can expect multiple medical appointments , hormone injections, ultrasounds, egg retrieval, embryo transfer, and careful monitoring by your fertility specialist."
        },
        {
          title: "How long does an IVF cycle typically take?",
          content: "An IVF cycle can take several weeks, from the initial evaluation and ovarian stimulation to embryo transfer. The exact timeline may vary based on individual circumstances."
        },
        {
          title: "Are there support groups or resources for individuals undergoing fertility treatments?",
          content: "Yes, numerous support groups, online forums, and organisations provide resources and emotional support for individuals and couples going through fertility treatments."
        },
       
        {
          title: "What is the cost of IVF and fertility medications?",
          content: "The cost of IVF and fertility medications can vary widely depending on location , clinic, and individual needs. Discussing fees and potential insurance coverage with your healthcare provider is essential."
        },
        {
          title: "Are there any lifestyle changes that can enhance fertility?",
          content: "Lifestyle changes such as maintaining a healthy weight, reducing stress, avoiding smoking, and moderating alcohol consumption can positively impact fertility."
  
        },
        {
          title: "Can fertility treatments be emotionally challenging?",
          content: "Yes, fertility treatments can be emotionally challenging due to the stress, uncertainty, and high expectations associated with the process. Seeking emotional support and counselling is often beneficial."
          
        },
        
      ]
  return (
    <>
    <Headertop title="IVF Tourism" desc="Reproductive technologies with IVF tourism"/>
    <div className="stresswhy-parent parent">
    <div className="stress-whychooseus-container" >
        <WhyChoose
        img={bg}
            bg="var(--cream)"
            indicator="why choose us"
            title="IVF Tourism"
            content="IVF tourism, or infertility tourism, has gained popularity due to the increasing prevalence of infertility worldwide. Lifestyle factors contribute to rising rates of delayed pregnancy and infertility in both men and women. Advancements in assisted reproductive technologies, combined with cost savings and additional benefits such as enhanced healthcare services, state-of-the-art facilities, superior hospitality, and personalised care, make international destinations attractive options for IVF tourism."

        />
    </div>
</div>

<div className="faqs_parent7 parent">
        <div className="faqs_container7_home container">
          <h2>FAQ for IVF Tourism</h2>
          {
            faq.slice(0,faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
          {
            faq.length >= faqstate &&  <button className='btn-dark-blue loadmore-btn' onClick={()=>setfaqstate(faqstate + 3)}>Load More</button>
          } 
          </center>
      
         
        </div>
      </div>
</>
  )
}

export default Ivf_tourism