import React, { useState } from 'react'
import Faqs from '../components/Faqs.js'
import WhyChoose from '../components/WhyChoose'
import img1 from '../assets/dr2.jpg'
import Card_section from '../components/Card_section';
import '../styles/executive_health.scss';
import comp from '../photos/executive/comprehensive.webp';
import personalize from '../photos/executive/personalizatretment.webp';
import potential from '../photos/executive/potential.webp';
import healthcheckup from '../photos/executive/healthcheckup.webp'
import Headertop from '../components/Headertop.js';

function Executive_health() {
  const [faqstate, setfaqstate] = useState(3)
  const faq = [
    {
      title: "What is a health check-up?",
      content: "A health check-up, a medical assessment or physical examination, is a comprehensive evaluation of an individual's overall health, including various tests and screenings."
    },
    {
      title: "Why are health check-ups important?",
      content: "Health check-ups are essential for early detection of health issues, monitoring existing conditions, and promoting overall wellbeing by identifying risk factors."
    },
    {
      title: "Where can I find health check-ups in the UK?",
      content: "You can find health check-up services in the UK through local healthcare providers, clinics, hospitals, and wellness centres."
    },
    {
      title: "What are the different types of health assessments available in the UK?",
      content: "Various types of health assessments in the UK include general health check-ups, wellness assessments, executive health check-ups, and specialised screenings for specific health concerns."
    },
    {
      title: "How often should I get a health check-up?",
      content: "The frequency of health check-ups may vary depending on age, gender, and health status. It's best to consult with a healthcare provider for personalised recommendations."
    },
    {
      title: "What is included in a typical health check-up?",
      content: "A typical health check-up may include measurements of vital signs, blood tests, screenings for conditions like diabetes and cholesterol levels, and physical examinations."
    },
    {
      title: "Can I get a medical check-up near me?",
      content: "You can find medical check-up facilities near you by searching online or contacting local healthcare providers."
    },
    {
      title: "Are there specific health check-up plans in the UK?",
      content: "Yes, many healthcare providers offer health check-up plans that include a range of assessments and tests tailored to different health needs."
    },
    {
      title: "What is a comprehensive health check-up?",
      content: "A comprehensive health check-up involves thoroughly evaluating various aspects of your health, including physical, mental, and lifestyle factors."
    },
    {
      title: "How can I prepare for a health check-up?",
      content: "To prepare for a health check-up, you may be asked to fast before specific tests, bring a list of medications, and be ready to discuss your medical history and lifestyle habits."
    },
    {
      title: "What is the cost of a health check-up in the UK?",
      content: "The cost of a health check-up in the UK varies depending on the type of assessment, location, and provider. Some check-ups may be covered by insurance or offered at discounted rates through wellness programs."
    },
    {
      title: "Are health check-ups covered by insurance?",
      content: "Some health insurance plans may cover the cost of health check-ups, but coverage can vary. It's essential to check with your insurance provider for specific details."
    },
    {
      title: "What should I do if I have legal concerns related to my healthcare?",
      content: "If you have legal concerns related to your healthcare, consult with an attorney who specialises in healthcare law to address your specific issues."
    },
    {
      title: "How long does a health check-up take?",
      content: "The duration of a health check-up can vary widely depending on the extent of assessments and tests involved. It may range from 30 minutes to several hours."
    },

    {
      title: "Can I request a specific test or screening during my health check-up?",
      content: "You can discuss your specific health concerns with your healthcare provider and request additional tests or screenings if needed."
    },
    {
      title: "Are health check-ups necessary for individuals of all ages?",
      content: "Health check-ups benefit individuals of all ages, as they help monitor health status, detect potential issues, and promote preventive care."
    },

  ]
  return (
    <>
      <Headertop title="Executive Check-ups" desc="Integrating wellness practices into check-ups" />
      <div className="stresswhy-parent parent executive_health ">
        <div className="stress-whychooseus-container" >
          <WhyChoose
            img={healthcheckup}
            bg='#ffff'
            title="Executive Health Check-ups"
            content="Safeguard your success and achieve peak performance with our executive health check-ups. We leverage leading practices to identify potential health issues early, ensuring preventive care becomes the cornerstone of your well-being.  Free up valuable time with our personalised assistant services, and access top-tier global healthcare through our doctors in diplomacy network.  We further empower your organisation with leadership training for clinicians and streamlined administrative support, fostering a thriving medical environment."
          />
        </div>
      </div>


      <Card_section
        mainbg="#efefef"
        heading="Approaches to Executive Health Assessments​"
        bg="#FFF8E5"
        btntext=''
        img={comp}
        card1='A Comprehensive Health Assessment'
        card1para="Executive health check-ups offer a thorough evaluation of an individual's overall health and fitness. This includes a physical examination, monitoring vital signs, assessing cholesterol levels, blood pressure, and conducting additional tests to determine the individual's risk for chronic diseases or other health concerns."

        img1={personalize}
        card2='Personalised Treatment Planned Check-ups'
        card2para="Executive health check-ups enable physicians to develop a personalised treatment plan tailored to the individual's age, gender, lifestyle habits, family history, and other factors influencing the management of chronic diseases or illnesses. This ensures effective management of existing conditions while also preventing future health issues through proactive measures."

        img2={potential}
        card3='Detecting Potential Health Issues Early'
        card3para="Executive health check-ups facilitate the early detection of potential health issues that might not be discernible through routine clinical tests or self-monitoring by patients. This is particularly crucial for individuals with a family history of specific medical conditions and those at elevated risk of certain illnesses due to their lifestyle choices. Early detection aids in averting serious complications and, ultimately, premature death."



      />
      <div className="faqs_parent5 parent">
        <div className="faqs_container5_home container">
          <h2>FAQ for Executive Health Check-upsenn</h2>
          {
            faq.slice(0, faqstate).map((data) => {
              return (
                <Faqs title={data.title} content={data.content} />
              )
            })
          }
          <center>
            {
              faq.length >= faqstate && <button className='btn-dark-blue loadmore-btn' onClick={() => setfaqstate(faqstate + 3)}>Load More</button>
            }
          </center>


        </div>
      </div>

    </>
  )
}

export default Executive_health