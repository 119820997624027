import React, { useState } from 'react'
import "../styles/faqs.scss"
import { IoIosArrowDown } from "react-icons/io";
const Faqs = (props) => {
    const [toggle, settoggle] = useState(false)
    return (
        <div className="faqs-parent">
        <div className="faqs_container">
            <div className="faq_top" onClick={() => settoggle(!toggle)}>
                <div className="faq_heading">
                    {props.title}
                </div>

                <span style={{ transform: toggle ? "rotateX(180deg)" : "rotateX(360deg)" }}>

                    <IoIosArrowDown />
                </span>
            </div>
            <div className={toggle ? "faq_bottom" : "faq_bottom1"}>
                {props.content}
            </div>
        </div>
        </div>
    )
}

export default Faqs