import React, { useState } from 'react'
import "../styles/electives.scss"
import Headertop from '../components/Headertop'
import WhyChoose from '../components/WhyChoose'
import Faqs from '../components/Faqs'
import img from "../photos/elective/elective.webp"
import SectionDividerCard from '../components/SectionDividerCard'
import Imageswiper from '../components/Imageswiper'
import img1 from "../photos/elective/doc1.webp"
import img2 from "../photos/elective/doc2.webp"
import img3 from "../photos/elective/doc3.webp"
import img4 from "../photos/elective/tour1.webp"
import img5 from "../photos/elective/tour2.webp"
import img6 from "../photos/elective/tour3.webp"
const Electives = () => {
    const accor = [
        {
            title: "Clinical Rotations (2 months - 2 years)",
            content: "Gain extensive experience in all clinical and surgical disciplines while working alongside renowned Sri Lankan medical specialists."
        },
        {
            title: "Cultural Immersions",
            content: "Take customised day trips to relax and discover the island's rich history, magnificent scenery, and bustling cities. "
        },
    ]
    const [faqstate, setfaqstate] = useState(3)
    const faq = [
        {
            title: " Personalised Rotations",
            content: "For a more focused learning experience, tailor your rotations around your academic interests."
        },
        {
            title: "Expert Mentorship",
            content: "Learn essential lessons from devoted doctors and surgeons in a supportive setting."
        },
        {
            title: "Unforgettable Explorations",
            content: "Spend your spare time exploring historic temples, stunning beaches, and lively cultural activities."
        },
        {
            title: "Affordable Opportunity",
            content: "The clinical experience is available at a competitive rate of £250 per week, with extra accommodation alternatives."
        },
    ]
    return (
        <>
            <Headertop title="Electives In Srilanka" desc=" Exploring diverse learning opportunities amidst Sri Lanka's rich cultural tapestry" />
            <div className="elective-parent parent">
                <div className="elective-containercontainer">
                    <WhyChoose
                        img={img}
                        indicator="why choose us" title="Unite Medical Growth with Cultural Exploration" content="Healthcare Concierge’s one-of-a-kind program that will enhance your medical education and provide hands-on experience while immersing you in Sri Lanka's breathtaking beauty" accor={accor} />
                </div>
            </div>

            <div className="faqs_parent5 parent">
                <div className="faqs_container5_home container">
                    <h2>Why Choose Healthcare Concierge's Program?</h2>
                    {
                        faq.slice(0, faqstate).map((data) => {
                            return (
                                <Faqs title={data.title} content={data.content} />
                            )
                        })
                    }
                    <center>
                        {
                            faq.length >= faqstate && <button className='btn-dark-blue loadmore-btn' onClick={() => setfaqstate(faqstate + 3)}>Load More</button>
                        }
                    </center>


                </div>
            </div>

            <div className="electives-cta-parent parent">
                <SectionDividerCard title="Are you ready for this transformative medical adventure?
" content="Contact Healthcare Concierge today to discuss your academic goals and create a unique Sri Lankan adventure!" link="/contact" />
            </div>
            <div className="elective-swiper parent">
                <div className="electiveswiper-container container">
                    <Imageswiper
                        img1={img1}
                        img2={img5}
                        img3={img3}
                        img4={img4}
                        img5={img2}
                        img6={img6}
                    />
                </div>
            </div>

        </>
    )
}
export default Electives