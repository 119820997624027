import React, { useEffect, useRef, useState } from 'react'
import "../styles/aboutuscard.scss"
import SectionIndicator from './SectionIndicator'
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import SectionDividerCard from './SectionDividerCard';
import CountUp from 'react-countup';
import AOS from "aos";
import "aos/dist/aos.css";
import BgVideo from "../videos/med.mp4"
import { MdOutlineReviews } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { useInView } from 'react-intersection-observer'
AOS.init({
  duration: 1000
});
const AboutusCard = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView])
  return (
    <div className='aboutus-card'>
      <div className="aboutus-card-left" data-aos="fade-up">
        <div className="left-box">
          <div className="inner-box">
            <video autoPlay loop muted className="bg-vid-2" style={{ position: "absolute", objectFit: "cover" }} height="100%" width="100%">
              <source src={BgVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="aboutus-card-right" data-aos="fade-down" ref={ref}>
        {/* <SectionIndicator title="about us" /> */}
        <p className='about-header'>Revolutionising Healthcare</p>
        <p className='about-content'>Healthcare Concierge have revolutionised traditional concierge medicine by integrating care in alignment with functional medicine. Healthcare isn’t what it used to be!  Most primary care physicians see an average of 30 to 50 individuals a day, with scarcely more than 15 minutes to devote to each patient, at the detriment of conversation and history taking, which comprise the art of diagnosis. When quantity supersedes quality, medical care inherently suffers.</p>
        {/* <div className="review-box-parent">
          <div className='review-box'><span className='golden-span'><IoCheckmarkCircleOutline /> </span>4.7 out of 5</div>
          <div className='review-box'><span className='golden-span'><IoCheckmarkCircleOutline /> </span>4.8 out of 5</div>
          <div className='review-box'><span className='golden-span'><IoCheckmarkCircleOutline /> </span>700+</div>
          <div className='review-box'><span className='golden-span'><IoCheckmarkCircleOutline /> </span>1200+</div>

        </div> */}
        {
          isVisible && 
          <div className="about-divider-counter">
            <div className="counter-box">
              <span className='counter-number'><CountUp end={260} duration={10}/>+</span>
              {/* <span className='counter-text'>Reviews</span> */}
              <span className='counter-icon'><MdOutlineReviews /></span>
            </div>
            <div className="counter-box">
              <span className='counter-number'><CountUp end={700} duration={10}/>+</span>
              {/* <span className='counter-text'>Linkedin Connections</span> */}
              <span className='counter-icon linkedin'><FaLinkedin /></span>
            </div>
            <div className="counter-box">
              <span className='counter-number'><CountUp end={1200} duration={10}/>+</span>
              {/* <span className='counter-text'>Facebook Followers</span> */}
              <span className='counter-icon facebookicon'><FaFacebookSquare /></span>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default AboutusCard