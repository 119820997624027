 import React, { useState } from 'react'
import "../styles/cosmetic.scss"
import Headertop from '../components/Headertop'
import WhyChoose from '../components/WhyChoose'
import AboutMain from '../components/AboutMain'
import machine from "../photos/cosmetic/strawberry-machine.webp"
import working from "../photos/cosmetic/working.webp"
import working2 from "../photos/cosmetic/wr2.webp"
import cand from "../photos/cosmetic/cand.webp"
import { RxCross1 } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import axios from 'axios';
import { TextField } from '@mui/material';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import Card_section from '../components/Card_section.js';
import healthchek from '../photos/health/healtha-checkup.webp';
import dental from '../photos/health/dental.webp';
import cosmatic from '../photos/health/cosmetic1.webp';
import ivf from '../photos/health/vip.webp';
import { IoMdDownload } from "react-icons/io";
const Cosmetic = () => {
  const array = [
    "Visible results after just one 12-minute treatment",
    "Non-surgical and minimally invasive",
    "Safe and effective for most skin types",
    "Reduces fat and contours the face",
    "Improves skin tone and elasticity",
    "Quick recovery time with minimal discomfort"

  ]
  const array2 = [
    "Puffiness in the face or neck",
    "Stubborn fat deposits resistant to diet and exercise",
    "Lack of facial definition",
    "Desire for a more youthful and sculpted appearance",
  ]
  const [formdata, setformdata] = useState({
    'name': "",
    "email": '',
    "contact": '',
    "message": ""
  })
  const submitHandler = (e) => {
    e.preventDefault()
    axios.post("https://www.api.healthcareconcierge.net/api/contacts/", formdata, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        setOpen(true);
        setformdata({
          'name': "",
          "email": '',
          "contact": '',
          "message": ""
        });
      })
      .catch(error => console.error('Error:', error));
  }

  // 
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false)
  }


  return (
    <>
      <Headertop title="Strawberry Facelift" desc="Enhance your natural beauty with our curated cosmetic collection."/>
      <div className="cosmetic-about parent">
        <div className="container">
          <WhyChoose title="Strawberry Lift: Non-Surgical Face Lift with Immediate Results" content="Achieve a lifted, contoured, and youthful appearance with the Strawberry Lift, a safe and effective laser treatment. This innovative procedure utilises low-level, class 3B cold red lasers to gently target and reduce fat cells in specific areas of your face." img={machine} />
        </div>
      </div>
      <div className="cosmetic-about2 parent">
        <div className="container">
          <WhyChoose title="How Strawberry Lift Works" content="The Strawberry Lift laser emits a precise, controlled beam of light that safely penetrates the skin's surface. This laser energy disrupts fat cell membranes, leading to a reduction in fat volume in the treated area. The treatment is comfortable and requires minimal downtime." img={working} direction="row-reverse" />
        </div>
      </div>
      <div className="cosmetic-about parent">
        <div className="container">
          <AboutMain section_title="Benefits of Strawberry Lift" mark={array} img={working2} />
        </div>
      </div>
      <div className="cosmetic-about2 parent">
        <div className="container">
          <AboutMain section_title="Who is a Candidate for Strawberry Lift?" content="The Strawberry Lift is ideal for individuals who desire a non-surgical option to address concerns like:" direction="row-reverse" mark={array2} img={cand} />
        </div>
      </div>
      <div className="cosmetic_card">
        <Card_section
          heading="WHAT WE OFFER?"
          // bg="var(--cream)"
          height="auto"
          img={healthchek}
          card1='What to Expect During Treatment'
          card1para='A consultation will be scheduled to discuss your goals and determine if Strawberry Lift is right for you. During the treatment, a qualified technician will apply a cooling gel to your skin and use the laser device to target the desired areas. The procedure is comfortable, and most patients experience minimal discomfort.'

          // img1={dental}
          // card2='Results and Aftercare'
          // card2para="
          // Following your Strawberry Lift treatment, you'll notice immediate visible improvements in your skin's texture and contour, with a smoother, tighter appearance. This procedure entails no downtime, allowing you to promptly resume your daily activities, enjoying the rejuvenating effects without interruption."

          img2={cosmatic}
          card3='Safe and Effective Treatment'
          card3para="
Strawberry Lift, an FDA-approved treatment, assures both safety and success. With millions worldwide benefiting from its innovative laser technology, it offers a proven track record of efficacy. Enjoy smoother, tighter skin without downtime—join those embracing this transformative procedure for lasting results."/>
      </div>

      <div className="cosmetic_contact parent ">
        <div className="cosmetic_contact_container container">
          <div className="cosmetic_contact_left">
            <h2>Contact Us Today</h2>
            <p className="contact-text">
              Schedule a consultation to learn more about Strawberry Lift and how it can help you achieve your desired aesthetic results. Our experienced team will be happy to answer your questions and guide you through the treatment process.
            </p>
          </div>
          <div className="contact_page_right">


            <form className="form_box" action="" onSubmit={submitHandler}>
              <TextField value={formdata.name} required id="filled-basic" label="Full Name" variant="outlined" onChange={(e) => setformdata({ ...formdata, 'name': e.target.value })} fullWidth />
              <TextField value={formdata.email} required id="filled-basic" type='email' label="Email" onChange={(e) => setformdata({ ...formdata, 'email': e.target.value })} variant="outlined" fullWidth />
              <TextField value={formdata.contact} required id="filled-basic" type='number' label="Contact" onChange={(e) => setformdata({ ...formdata, 'contact': e.target.value })} variant="outlined" fullWidth />
              <TextField value={formdata.message} id="filled-basic" type='text' label="Message" multiline onChange={(e) => setformdata({ ...formdata, 'message': e.target.value })} variant="outlined" fullWidth rows={6} />
              <button className='btn-dark-blue' type='submit'>Send Message</button>
            </form>
          </div>
        </div>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
          key={'bottom' + 'right'}
        >
          Message sent!
        </MuiAlert>
      </Snackbar>
      <a target='#' href='https://drive.google.com/file/d/1DRlYFld0CojnBN-f-TKXyBZEHHhzlT7I/view?usp=drive_link' className='down-broch btn-dark-blue '>
        Download Brochure
        <span className='don'>
          <IoMdDownload />
        </span>
      </a>
    </>

  )
}

export default Cosmetic