import React from 'react'
import "../styles/services.scss"
import WhyChoose from "../components/WhyChoose.js"
import Expertise from '../components/Expertise.js'
import SectionDividerCard from '../components/SectionDividerCard.js'
import { Link } from 'react-router-dom'
import SectionIndicator from '../components/SectionIndicator.js'
import Headertop from '../components/Headertop.js'
const Services = () => {
    const cardcontent =
        [
            {
                title: "title",
                description: "asdasd asda sdas dad asdas dasdas dasd asdas dasdas das",
                image: "https://img.freepik.com/free-photo/portrait-3d-male-doctor_23-2151107212.jpg?t=st=1709538482~exp=1709542082~hmac=f36b67ae3cff5524838504dab2ece8271af02de171d2dbc6bf56f390aca118aa&w=740",
                content: []
            },
            {
                title: "title2",
                description: "asdasd asda sdas dad asdas dasdas dasd asdas dasdas das",
                image: "https://img.freepik.com/free-photo/portrait-3d-male-doctor_23-2151107212.jpg?t=st=1709538482~exp=1709542082~hmac=f36b67ae3cff5524838504dab2ece8271af02de171d2dbc6bf56f390aca118aa&w=740",
                content: []
            },
            {
                title: "title3",
                description: "asdasd asda sdas dad asdas dasdas dasd asdas dasdas das",
                image: "https://img.freepik.com/free-photo/portrait-3d-male-doctor_23-2151107212.jpg?t=st=1709538482~exp=1709542082~hmac=f36b67ae3cff5524838504dab2ece8271af02de171d2dbc6bf56f390aca118aa&w=740",
                content: []
            },
        ]
    const data2 = [
        {
            title: "advantages of doctor visit",
            content: "adff wef wefwe wefwe fwef wefwef fewf sdf qwerty."
        }
    ]
    const data3 = [
        {
            title: "advantages of doctor visit",
            content: "adff wef wefwe wefwe fwef wefwef fewf sdf qwerty."
        },
        {
            title: "advantages of doctor visit",
            content: "adff wef wefwe wefwe fwef wefwef fewf sdf qwerty."
        },
        {
            title: "advantages of doctor visit",
            content: "adff wef wefwe wefwe fwef wefwef fewf sdf qwerty."
        },
        {
            title: "advantages of doctor visit",
            content: "adff wef wefwe wefwe fwef wefwef fewf sdf qwerty."
        },
        {
            title: "advantages of doctor visit",
            content: "adff wef wefwe wefwe fwef wefwef fewf sdf qwerty."
        },
    ]
    const data4 = [
        {
            title: "advantages of doctor visit",
            content: "adff wef wefwe wefwe fwef wefwef fewf sdf qwerty."
        }
    ]
    const data5 = [
        {
            title: "advantages of doctor visit",
            content: "adff wef wefwe wefwe fwef wefwef fewf sdf qwerty."
        }
    ]
    return (
        <>
<Headertop title="Our Expertise" desc="We provide you with worldwide coverage of our resourceful, always up-to-date, growing network of personal doctors and professionals seeking to provide you with the highest level medical care & services as Follow"/>
    
            <div className="services-parent parent">
                <div className="medical-service-conainer container">
                    <div className="medical-service-title title">
                        Medical Equipment
                    </div>
                    <div className="medical-service-description content">
                        Device Supply
                    </div>
                    <WhyChoose indicator="why choose us" title="Why People Trust Us" content="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque ducimus quibusdam quam esse pariatur facilis ab voluptas deserunt natus voluptatem." />
                </div>
                <div className="medial-services container">
                    <Expertise title="Why Choose Us" card={cardcontent} />
                </div>
                <div className="services-whychooseus-container" >
                    <WhyChoose indicator="why choose us" title="Doctor Home Visits" content="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque ducimus quibusdam quam esse pariatur facilis ab voluptas deserunt natus voluptatem." accor={data2} direction="row-reverse" />
                </div>
                <div className="services-whychooseus-container" >
                    <WhyChoose indicator="why choose us" title="Doctor Home Visits" content="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque ducimus quibusdam quam esse pariatur facilis ab voluptas deserunt natus voluptatem." accor={data3} />
                </div>
                <div className="services-whychooseus-container" >
                    <WhyChoose indicator="why choose us" title="Doctor Home Visits" content="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque ducimus quibusdam quam esse pariatur facilis ab voluptas deserunt natus voluptatem." accor={data4} direction="row-reverse" />
                </div>
                <div className="services-whychooseus-container" >
                    <WhyChoose indicator="why choose us" title="Doctor Home Visits" content="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque ducimus quibusdam quam esse pariatur facilis ab voluptas deserunt natus voluptatem." accor={data5} />
                </div>
                <div className="services-last-section container">
                    <SectionIndicator title="Faqs" />
                    <div className="title">Surgical Masks/Medical Face Mask</div>
                    <div className="content">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam eaque cum numquam quasi exercitationem eveniet modi, excepturi veritatis? Repudiandae maiores laudantium cumque quibusdam distinctio labore aliquid voluptatem totam iste et sint fugiat ea provident, culpa sunt non animi architecto atque! Facere laborum culpa accusantium odio libero perferendis fuga magnam quisquam?</div>
                    <Link className='btn-dark-blue'>View faqs</Link>
                </div>
            </div>
        </>
    )
}

export default Services